import { numberWithCommas } from '@rentpath/web-utils'
import {
  Amenity,
  BathFilter,
  BedFilter,
  ListingSort,
  PetPolicyFilter,
  PropertyTypeFilter,
  RentPricePreference,
} from '../../../__generated__/api-types'
import type {
  RefinementMap,
  SeoBucket,
} from '../../../features/search/search-page.types'
import { detailPageBrandText } from '@brand/detail/brand-text'
import {
  MIN_PRICE_PREFIX,
  MAX_PRICE_PREFIX,
  BEDROOM_SUFFIX,
  BATHROOM_SUFFIX,
  STUDIO_SLUG,
} from './refinements.const'

export const bedSlugMap: { [key: string]: string } = {
  [BedFilter.Studio]: STUDIO_SLUG,
  [BedFilter.OneBedroom]: `1${BEDROOM_SUFFIX}`,
  [BedFilter.TwoBedroom]: `2${BEDROOM_SUFFIX}`,
  [BedFilter.ThreeBedroom]: `3${BEDROOM_SUFFIX}`,
  [BedFilter.FourOrMoreBedrooms]: `4${BEDROOM_SUFFIX}`,
}

export const bedRefinementMap: RefinementMap<BedFilter> = {
  [bedSlugMap[BedFilter.Studio]]: {
    label: 'Studio',
    seoBucket: 'floorplans',
    seoLabel: 'Studio',
    seoSortKey: 10,
    value: BedFilter.Studio,
    hash: '1z141wj',
    filterKey: 'bed',
    shortLabel: 'Studio',
  },
  [bedSlugMap[BedFilter.OneBedroom]]: {
    label: '1 Bed',
    seoBucket: 'floorplans',
    seoLabel: '1 Bedroom',
    seoSortKey: 20,
    headlineLabel: '1 Bedroom',
    value: BedFilter.OneBedroom,
    hash: '1z141xt',
    filterKey: 'bed',
    shortLabel: '1',
  },
  [bedSlugMap[BedFilter.TwoBedroom]]: {
    label: '2 Beds',
    seoBucket: 'floorplans',
    seoLabel: '2 Bedroom',
    seoSortKey: 30,
    headlineLabel: '2 Bedroom',
    value: BedFilter.TwoBedroom,
    hash: '1z141y8',
    filterKey: 'bed',
    shortLabel: '2',
  },
  [bedSlugMap[BedFilter.ThreeBedroom]]: {
    label: '3 Beds',
    seoBucket: 'floorplans',
    seoLabel: '3 Bedroom',
    seoSortKey: 40,
    headlineLabel: '3 Bedroom',
    value: BedFilter.ThreeBedroom,
    hash: '1z141xs',
    filterKey: 'bed',
    shortLabel: '3',
  },
  [bedSlugMap[BedFilter.FourOrMoreBedrooms]]: {
    label: '4+ Beds',
    seoBucket: 'floorplans',
    seoLabel: '4+ Bedroom',
    seoSortKey: 50,
    headlineLabel: '4+ Bedroom',
    value: BedFilter.FourOrMoreBedrooms,
    hash: '1z141to',
    filterKey: 'bed',
    shortLabel: '4+',
  },
}

export const bedSlugs = Object.keys(bedRefinementMap)
export const bathSlugMap: { [key: string]: string } = {
  [BathFilter.OneBathroom]: `1${BATHROOM_SUFFIX}`,
  [BathFilter.TwoBathroom]: `2${BATHROOM_SUFFIX}`,
  [BathFilter.ThreeOrMoreBathrooms]: `3${BATHROOM_SUFFIX}`,
}
export const bathRefinementMap: RefinementMap<BathFilter> = {
  [bathSlugMap[BathFilter.OneBathroom]]: {
    label: '1 Bath',
    seoBucket: 'floorplans',
    seoLabel: '1 Bathroom',
    seoSortKey: 60,
    headlineLabel: '1 Bathroom',
    value: BathFilter.OneBathroom,
    hash: '1z141xu',
    filterKey: 'bath',
  },
  [bathSlugMap[BathFilter.TwoBathroom]]: {
    label: '2 Baths',
    seoBucket: 'floorplans',
    seoLabel: '2 Bathroom',
    seoSortKey: 70,
    headlineLabel: '2 Bathroom',
    value: BathFilter.TwoBathroom,
    hash: '1z141y7',
    filterKey: 'bath',
  },
  [bathSlugMap[BathFilter.ThreeOrMoreBathrooms]]: {
    label: '3+ Baths',
    seoBucket: 'floorplans',
    seoLabel: '3+ Bathroom',
    seoSortKey: 80,
    headlineLabel: '3+ Bathroom',
    value: BathFilter.ThreeOrMoreBathrooms,
    hash: '1z141x2',
    filterKey: 'bath',
  },
}
export const bathSlugs = Object.keys(bathRefinementMap)

export const priceValues: number[] = [
  300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600,
  1700, 1800, 1900, 2000, 2100,

  2300, 2500,

  3000, 4000, 5000, 6000, 7000,

  9000, 11000,
]

export const createMinPriceSlug = (price: number) =>
  `${MIN_PRICE_PREFIX}${price}`

export const minPriceRefinementMap = priceValues.reduce<RefinementMap<number>>(
  (acc, price) => {
    acc[createMinPriceSlug(price)] = {
      label: `$${numberWithCommas(price)}`,
      value: price,
      seoBucket: 'priceOver' as SeoBucket,
      seoLabel: null,
      seoSortKey: price,
      noIndex: true,
      headlineLabel: `over $${numberWithCommas(price)}`,
      filterKey: 'prices',
    }
    return acc
  },
  {}
)
export const minPriceSlugs = Object.keys(minPriceRefinementMap)

export const createMaxPriceSlug = (price: number) =>
  `${MAX_PRICE_PREFIX}${price}`

export const maxPriceRefinementMap = priceValues.reduce<RefinementMap<number>>(
  (acc, price) => {
    acc[createMaxPriceSlug(price)] = {
      label: `$${numberWithCommas(price)}`,
      value: price,
      seoBucket: 'priceUnder' as SeoBucket,
      seoLabel: null,
      seoSortKey: price,
      noIndex: false,
      headlineLabel: `under $${numberWithCommas(price)}`,
      filterKey: 'prices',
    }
    return acc
  },
  {}
)
export const maxPriceSlugs = Object.keys(maxPriceRefinementMap)

export const priceRefinementMap: RefinementMap<number> = {
  ...minPriceRefinementMap,
  ...maxPriceRefinementMap,
}

export const amenitySlugMap: { [key: string]: string } = {
  [Amenity.AirConditioning]: 'air-conditioning',
  [Amenity.AssistedLiving]: 'assisted-living',
  [Amenity.AttFiber]: 'att-fiber',
  [Amenity.AvailableNow]: 'available-now',
  [Amenity.CableReady]: 'cable-ready',
  [Amenity.College]: 'college',
  [Amenity.ControlledAccess]: 'controlled-limited-access',
  [Amenity.CorporateBillingAvailable]: 'corporate-billing-available',
  [Amenity.DisabilityAccess]: 'disability-access',
  [Amenity.Dishwasher]: 'dishwasher',
  [Amenity.Elevator]: 'elevator',
  [Amenity.EvCharging]: 'ev-charging',
  [Amenity.ExtraStorage]: 'extra-storage',
  [Amenity.Fireplace]: 'fireplace',
  [Amenity.FitnessCenter]: 'fitness-center',
  [Amenity.FurnishedAvailable]: 'furnished',
  [Amenity.Garage]: 'garage',
  [Amenity.GatedAccess]: 'gated-access',
  [Amenity.HardwoodFloors]: 'hardwood-floor',
  [Amenity.HighlyRated]: 'best',
  [Amenity.HighSpeedInternetAccess]: 'high-speed-internet-access',
  [Amenity.IncomeRestricted]: 'income-restricted',
  [Amenity.IndependentLiving]: 'independent-living',
  [Amenity.LaundryFacility]: 'laundry-facility',
  [Amenity.LoftLayout]: 'loft',
  [Amenity.Military]: 'military',
  [Amenity.NewConstruction]: 'new-construction',
  [Amenity.NewRenovatedInterior]: 'remodeled',
  [Amenity.NoCreditCheck]: 'no-credit-check',
  [Amenity.OnSiteManagement]: 'onsite-personnel',
  [Amenity.OversizedClosets]: 'oversized-closets',
  [Amenity.Balcony]: 'balcony',
  [Amenity.Pool]: 'pool',
  [Amenity.PublicTransportation]: 'public-transportation',
  [Amenity.RecentlyUpdated]: 'new',
  [Amenity.SeniorLiving]: 'senior-living',
  [Amenity.ShortTermAvailable]: 'short-term',
  [Amenity.UtilitiesIncluded]: 'utilities-included',
  [Amenity.View]: 'view',
  [Amenity.VirtualTours]: 'virtual-tours',
  [Amenity.WasherDryerConnections]: 'washer-and-dryer-connections',
  [Amenity.WasherDryerInUnit]: 'washer-and-dryer-in-unit',
  [Amenity.Waterfront]: 'waterfront',
}
export const amenityRefinementMap: RefinementMap<Amenity> = {
  [amenitySlugMap[Amenity.AirConditioning]]: {
    label: 'AC',
    seoBucket: 'popular',
    seoLabel: 'AC',
    value: Amenity.AirConditioning,
    hash: '2p',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.AttFiber]]: {
    // eslint-disable-next-line react/display-name
    label: 'AT&T Fiber',
    headlineLabel: 'AT&T Fiber',
    seoBucket: 'popular',
    seoLabel: 'AT&T Fiber',
    value: Amenity.AttFiber,
    hash: '4i0',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.AvailableNow]]: {
    label: 'Available Now',
    seoBucket: 'popular',
    seoLabel: 'Available Now',
    value: Amenity.AvailableNow,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.CableReady]]: {
    label: 'TV Cable Ready',
    seoBucket: 'popular',
    seoLabel: 'TV Cable Ready',
    value: Amenity.CableReady,
    hash: '2r',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.College]]: {
    label: 'College',
    seoBucket: 'popular',
    seoLabel: 'College Accommodation',
    value: Amenity.College,
    hash: '4mf',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.ControlledAccess]]: {
    label: 'Key Card Entry',
    seoBucket: 'popular',
    seoLabel: 'Key Card Entry',
    value: Amenity.ControlledAccess,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.CorporateBillingAvailable]]: {
    label: 'Corporate',
    seoBucket: 'popular',
    seoLabel: 'Corporate Housing Available',
    value: Amenity.CorporateBillingAvailable,
    hash: '4lc',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.DisabilityAccess]]: {
    label: 'Disability Access',
    seoBucket: 'popular',
    seoLabel: 'Disability Accessible',
    value: Amenity.DisabilityAccess,
    hash: '4ia',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Dishwasher]]: {
    label: 'Dishwasher',
    seoBucket: 'popular',
    seoLabel: 'Dishwasher Equipped',
    value: Amenity.Dishwasher,
    hash: '2s',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Elevator]]: {
    label: 'Elevator',
    seoBucket: 'popular',
    seoLabel: 'Elevator Equipped',
    value: Amenity.Elevator,
    hash: '4ko',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.EvCharging]]: {
    label: 'EV Chargers',
    seoBucket: 'popular',
    seoLabel: 'EV Charging Equipped',
    value: Amenity.EvCharging,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.ExtraStorage]]: {
    label: 'Additional Storage Space',
    seoBucket: 'popular',
    seoLabel: 'Additional Storage Space',
    value: Amenity.ExtraStorage,
    hash: '3hb',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Fireplace]]: {
    label: 'Fireplace',
    seoBucket: 'popular',
    seoLabel: 'Fireplace',
    value: Amenity.Fireplace,
    hash: '2t',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.FitnessCenter]]: {
    label: 'Gym',
    seoBucket: 'popular',
    seoLabel: 'Gym',
    value: Amenity.FitnessCenter,
    hash: '3hc',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.FurnishedAvailable]]: {
    label: 'Furnished Available',
    seoBucket: 'popular',
    seoLabel: 'Furnished',
    value: Amenity.FurnishedAvailable,
    hash: '4ic',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Garage]]: {
    label: 'Garage',
    seoBucket: 'popular',
    seoLabel: 'Garage',
    value: Amenity.Garage,
    hash: '3hj',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.GatedAccess]]: {
    label: 'Gated Access',
    seoBucket: 'popular',
    seoLabel: 'Gated Access',
    value: Amenity.GatedAccess,
    hash: '3hdc',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.HardwoodFloors]]: {
    label: 'Hardwood Floors',
    seoBucket: 'popular',
    seoLabel: 'Hardwood Floor',
    value: Amenity.HardwoodFloors,
    hash: '4kr',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.HighSpeedInternetAccess]]: {
    label: 'High-Speed Internet',
    seoBucket: 'popular',
    seoLabel: 'High-Speed Internet Available',
    value: Amenity.HighSpeedInternetAccess,
    hash: '3he',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.LaundryFacility]]: {
    label: 'On-Site Laundry',
    seoBucket: 'popular',
    seoLabel: 'On-Site Laundry',
    value: Amenity.LaundryFacility,
    hash: '3hf',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.LoftLayout]]: {
    label: 'Loft-style',
    seoBucket: 'popular',
    seoLabel: 'Loft-style',
    value: Amenity.LoftLayout,
    hash: '4ku',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Military]]: {
    label: 'Military',
    seoBucket: 'popular',
    seoLabel: 'Military',
    value: Amenity.Military,
    hash: '4me',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.NewConstruction]]: {
    label: 'New Construction',
    seoBucket: 'popular',
    seoLabel: 'New Construction',
    value: Amenity.NewConstruction,
    hash: '4nj',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.OnSiteManagement]]: {
    label: 'Onsite Personnel',
    seoBucket: 'popular',
    seoLabel: 'Onsite Staffed',
    value: Amenity.OnSiteManagement,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.OversizedClosets]]: {
    label: 'Large Closets',
    seoBucket: 'popular',
    seoLabel: 'Walk-in Closet Equipped',
    value: Amenity.OversizedClosets,
    hash: '2u',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Balcony]]: {
    label: 'Balcony',
    seoBucket: 'popular',
    seoLabel: 'Patio',
    value: Amenity.Balcony,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Pool]]: {
    label: 'Pool',
    seoBucket: 'popular',
    seoLabel: 'Pool Equipped',
    value: Amenity.Pool,
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.PublicTransportation]]: {
    label: 'Public Transit Nearby',
    seoBucket: 'popular',
    seoLabel: 'Public Transit Nearby',
    value: Amenity.PublicTransportation,
    hash: '3hg',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.NewRenovatedInterior]]: {
    label: 'Remodeled',
    seoBucket: 'popular',
    seoLabel: 'Remodeled',
    value: Amenity.NewRenovatedInterior,
    hash: '4nk',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.ShortTermAvailable]]: {
    label: 'Short Term Leases',
    seoBucket: 'popular',
    seoLabel: 'Short Term',
    value: Amenity.ShortTermAvailable,
    hash: '4id',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.UtilitiesIncluded]]: {
    label: 'Utilities Included',
    seoBucket: 'popular',
    seoLabel: 'Utilities Included',
    value: Amenity.UtilitiesIncluded,
    hash: '4nu',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.View]]: {
    label: 'Scenic Views',
    seoBucket: 'popular',
    seoLabel: 'Scenic Views',
    value: Amenity.View,
    hash: '2v',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.WasherDryerConnections]]: {
    label: 'W/D Connections',
    seoBucket: 'popular',
    seoLabel: 'Washer/Dryer Connections',
    value: Amenity.WasherDryerConnections,
    hash: '2w',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.WasherDryerInUnit]]: {
    label: 'Washer/Dryer In Unit',
    seoBucket: 'popular',
    seoLabel: 'Washer/Dryer In Unit',
    value: Amenity.WasherDryerInUnit,
    hash: '31',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.Waterfront]]: {
    label: 'Waterfront',
    seoBucket: 'popular',
    seoLabel: 'Waterfront',
    value: Amenity.Waterfront,
    hash: '4nh',
    filterKey: 'amenities',
  },
}

export const topFeaturesMap: RefinementMap<Amenity> = {
  'air-conditioning': amenityRefinementMap['air-conditioning'],
  'washer-and-dryer-in-unit': amenityRefinementMap['washer-and-dryer-in-unit'],
}

export const amenitySlugs = Object.keys(amenityRefinementMap)

export const lifestyleRefinementMap: RefinementMap<Amenity> = {
  [amenitySlugMap[Amenity.IncomeRestricted]]: {
    label: 'Income Restricted',
    seoBucket: 'popular',
    seoLabel: 'Income Restricted',
    value: Amenity.IncomeRestricted,
    hash: '4mb',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.NoCreditCheck]]: {
    label: 'No Credit Check',
    seoBucket: 'popular',
    seoLabel: 'No Credit Check',
    value: Amenity.NoCreditCheck,
    filterKey: 'amenities',
  },
}
export const lifestyleSlugs = Object.keys(lifestyleRefinementMap)

export const seniorLivingRefinementMap: RefinementMap<Amenity> = {
  [amenitySlugMap[Amenity.AssistedLiving]]: {
    label: 'Assisted Living',
    seoBucket: 'popular',
    seoLabel: 'Assisted Living',
    value: Amenity.AssistedLiving,
    hash: '4in',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.IndependentLiving]]: {
    label: 'Independent Living',
    seoBucket: 'popular',
    seoLabel: 'Independent Living',
    value: Amenity.IndependentLiving,
    hash: '4im',
    filterKey: 'amenities',
  },
  [amenitySlugMap[Amenity.SeniorLiving]]: {
    label: 'Senior Living',
    seoBucket: 'popular',
    seoLabel: 'Senior Living',
    value: Amenity.SeniorLiving,
    hash: '4ma',
    filterKey: 'amenities',
  },
}

export const seniorLivingSlugs = Object.keys(seniorLivingRefinementMap)

export const petPolicySlugMap: { [key: string]: string } = {
  [PetPolicyFilter.PetFriendly]: 'pet-friendly',
  [PetPolicyFilter.CatsOk]: 'cat-friendly',
  [PetPolicyFilter.DogsOk]: 'dog-friendly',
  [PetPolicyFilter.NoPets]: 'no-pets',
}

export const petRefinementMap: RefinementMap<PetPolicyFilter> = {
  [petPolicySlugMap[PetPolicyFilter.PetFriendly]]: {
    label: 'Pet Friendly',
    seoBucket: 'popular',
    seoLabel: 'Pet Friendly',
    value: PetPolicyFilter.PetFriendly,
    hash: '4ib',
    filterKey: 'amenities',
  },
  [petPolicySlugMap[PetPolicyFilter.CatsOk]]: {
    label: 'Cats OK',
    seoBucket: 'popular',
    seoLabel: 'Cats OK',
    value: PetPolicyFilter.CatsOk,
    filterKey: 'petPolicies',
  },
  [petPolicySlugMap[PetPolicyFilter.DogsOk]]: {
    label: 'Dogs OK',
    seoBucket: 'popular',
    seoLabel: 'Dogs OK',
    value: PetPolicyFilter.DogsOk,
    filterKey: 'petPolicies',
  },
  [petPolicySlugMap[PetPolicyFilter.NoPets]]: {
    label: 'No Pets',
    seoBucket: 'popular',
    seoLabel: 'No Pets',
    value: PetPolicyFilter.NoPets,
    filterKey: 'petPolicies',
  },
}
export const petSlugs = Object.keys(petRefinementMap)

export const propertyTypeSlugMap: { [key: string]: string } = {
  [PropertyTypeFilter.Apartments]: 'apartments',
  [PropertyTypeFilter.Townhouses]: 'townhomes',
  [PropertyTypeFilter.Condos]: 'condos',
  [PropertyTypeFilter.Houses]: 'houses',
  [PropertyTypeFilter.Duplexes]: 'duplexes',
  [PropertyTypeFilter.Triplexes]: 'triplexes',
}

export const propertyTypeRefinementMap: RefinementMap<PropertyTypeFilter> = {
  [propertyTypeSlugMap[PropertyTypeFilter.Apartments]]: {
    label: 'Apartments',
    seoBucket: 'unknown',
    seoLabel: 'Apartments',
    value: PropertyTypeFilter.Apartments,
    filterKey: 'propertyTypes',
  },
  [propertyTypeSlugMap[PropertyTypeFilter.Townhouses]]: {
    label: 'Townhomes',
    seoBucket: 'unknown',
    seoLabel: 'Townhomes',
    value: PropertyTypeFilter.Townhouses,
    filterKey: 'propertyTypes',
  },
  [propertyTypeSlugMap[PropertyTypeFilter.Condos]]: {
    label: 'Condos',
    seoBucket: 'unknown',
    seoLabel: 'Condos',
    value: PropertyTypeFilter.Condos,
    filterKey: 'propertyTypes',
  },
  [propertyTypeSlugMap[PropertyTypeFilter.Houses]]: {
    label: 'Houses',
    seoBucket: 'unknown',
    seoLabel: 'Houses',
    value: PropertyTypeFilter.Houses,
    filterKey: 'propertyTypes',
  },
}

export const housePropertyTypeRefinements: RefinementMap<PropertyTypeFilter> = {
  houses: propertyTypeRefinementMap.houses,
  duplexes: {
    label: 'Duplexes',
    seoBucket: 'unknown',
    seoLabel: 'Duplexes',
    value: PropertyTypeFilter.Duplexes,
    filterKey: 'propertyTypes',
  },
  triplexes: {
    label: 'Triplexes',
    seoBucket: 'unknown',
    seoLabel: 'Triplexes',
    value: PropertyTypeFilter.Triplexes,
    filterKey: 'propertyTypes',
  },
}
export const propertyTypeSlugs = Object.keys(propertyTypeRefinementMap)

const sqFtPrefix = 'sqft'
const sqFtMinSlugPart = 'min'
const sqFtValues: number[] = [
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
  1500, 1600,
]
export const createMinSqFtSlug = (sqFt: number) =>
  `${sqFtPrefix}-${sqFtMinSlugPart}-${sqFt}`

export const sqFtRefinementMap = sqFtValues.reduce<RefinementMap<number>>(
  (acc, sqFt) => {
    acc[createMinSqFtSlug(sqFt)] = {
      label: `${numberWithCommas(sqFt)} ft+`,
      seoBucket: 'popular',
      seoLabel: `${numberWithCommas(sqFt)} Square Foot`,
      seoSortKey: sqFt,
      noIndex: true,
      value: sqFt,
      filterKey: 'squareFeet',
    }

    return acc
  },
  {}
)
export const sqFtSlugs = Object.keys(sqFtRefinementMap)

export const sortRefinementMap: RefinementMap<ListingSort> = {
  'sort-price-low': {
    label: 'Price (Low to High)',
    seoBucket: 'popular',
    seoLabel: '',
    headlineLabel: '',
    value: ListingSort.PriceLowToHigh,
  },
  'sort-price-high': {
    label: 'Price (High to Low)',
    seoBucket: 'popular',
    seoLabel: '',
    headlineLabel: '',
    value: ListingSort.PriceHighToLow,
  },
  'sort-rating-high': {
    label: 'Ratings (High to Low)',
    seoBucket: 'popular',
    headlineLabel: 'Popular',
    seoLabel: 'Top Rated',
    value: ListingSort.RatingHighToLow,
  },
}
export const sortSlugs = Object.keys(sortRefinementMap)

export const tourFromHomeRefinementMap: RefinementMap<Amenity> = {
  [amenitySlugMap[Amenity.VirtualTours]]: {
    label: '3D Tours or Videos',
    seoBucket: 'popular',
    seoLabel: 'Virtual Tours',
    value: Amenity.VirtualTours,
    headlineLabel: 'Virtual Tours',
    hash: '4a4',
    filterKey: 'amenities',
  },
}
export const tourFromHomeSlugs = Object.keys(tourFromHomeRefinementMap)

export const hotDealsRefinementMap: RefinementMap<boolean> = {
  'rent-deals-specials': {
    label: detailPageBrandText.hotDealsSectionName,
    seoBucket: 'popular',
    seoLabel: detailPageBrandText.hotDealsSectionName,
    value: true,
    hash: '3bf',
    filterKey: 'withDeals',
  },
}
export const hotDealsSlug = Object.keys(hotDealsRefinementMap)[0]

export const rentPricePreferenceRefinementMap: RefinementMap<
  RentPricePreference | ListingSort
> = {
  cheap: {
    label: 'Cheap',
    seoBucket: 'popular',
    seoLabel: 'Cheap',
    headlineLabel: 'Cheap',
    value: RentPricePreference.Cheap,
    filterKey: 'rentPricePreference',
  },
  luxury: {
    label: 'Luxury',
    seoBucket: 'popular',
    seoLabel: 'Luxury',
    headlineLabel: 'Luxury',
    value: ListingSort.PriceHighToLow,
    filterKey: 'rentPricePreference',
  },
}

export const rentPricePreferenceRefinementMapSlugs = Object.keys(
  rentPricePreferenceRefinementMap
)

export const mixedAmenityRefinementMap: RefinementMap<Amenity> = {
  ...tourFromHomeRefinementMap,
  ...amenityRefinementMap,
  ...lifestyleRefinementMap,
  ...seniorLivingRefinementMap,
}
export const mixedAmenitySlugs = Object.keys(mixedAmenityRefinementMap)

export const refinementMap = {
  ...bedRefinementMap,
  ...bathRefinementMap,
  ...priceRefinementMap,
  ...amenityRefinementMap,
  ...petRefinementMap,
  ...lifestyleRefinementMap,
  ...seniorLivingRefinementMap,
  ...sqFtRefinementMap,
  ...propertyTypeRefinementMap,
  ...sortRefinementMap,
  ...rentPricePreferenceRefinementMap,
  ...tourFromHomeRefinementMap,
  ...hotDealsRefinementMap,
}

export const trackingFilterCategoryMap: { [key: string]: string } = {
  // formatted differently in the page data
  prices: 'priceRange',
  squareFeet: 'minSqft',
  withDeals: 'hotDeals',
  moveInDate: 'moveInDate',

  STUDIO: 'beds',
  ONE_BEDROOM: 'beds',
  TWO_BEDROOM: 'beds',
  THREE_BEDROOM: 'beds',
  FOUR_OR_MORE_BEDROOMS: 'beds',
  ONE_BATHROOM: 'baths',
  TWO_BATHROOM: 'baths',
  THREE_OR_MORE_BATHROOMS: 'baths',
  CATS_OK: 'petPolicy',
  DOGS_OK: 'petPolicy',
  PET_FRIENDLY: 'petPolicy',
  NO_PETS: 'petPolicy',
  VIRTUAL_TOURS: 'tourFromHome',
  WITH_DEALS: 'withDeals',
  APARTMENTS: 'propertyTypes',
  CONDOS: 'propertyTypes',
  HOUSES: 'propertyTypes',
  TOWNHOUSES: 'propertyTypes',
  DUPLEXES: 'propertyTypes',
  TRIPLEXES: 'propertyTypes',
  AIR_CONDITIONING: 'amenities',
  ATT_FIBER: 'amenities',
  BALCONY: 'amenities',
  CABLE_READY: 'amenities',
  COLLEGE: 'amenities',
  CONTROLLED_ACCESS: 'amenities',
  CORPORATE_BILLING_AVAILABLE: 'amenities',
  DISABILITY_ACCESS: 'amenities',
  DISHWASHER: 'amenities',
  ELEVATOR: 'amenities',
  EXTRA_STORAGE: 'amenities',
  FIREPLACE: 'amenities',
  FITNESS_CENTER: 'amenities',
  FURNISHED_AVAILABLE: 'amenities',
  GARAGE: 'amenities',
  GATED_ACCESS: 'amenities',
  HARDWOOD_FLOORS: 'amenities',
  HIGH_SPEED_INTERNET_ACCESS: 'amenities',
  LAUNDRY_FACILITY: 'amenities',
  LOFT_LAYOUT: 'amenities',
  MILITARY: 'amenities',
  NEW_RENOVATED_INTERIOR: 'amenities',
  ON_SITE_MANAGEMENT: 'amenities',
  OVERSIZED_CLOSETS: 'amenities',
  POOL: 'amenities',
  PUBLIC_TRANSPORTATION: 'amenities',
  SHORT_TERM_AVAILABLE: 'amenities',
  UTILITIES_INCLUDED: 'amenities',
  VIEW: 'amenities',
  WASHER_DRYER_CONNECTIONS: 'amenities',
  WASHER_DRYER_IN_UNIT: 'amenities',
  WATERFRONT: 'amenities',
  INCOME_RESTRICTED: 'amenities',
  NO_CREDIT_CHECK: 'amenities',
  NEW_CONSTRUCTION: 'amenities',
  HIGHLY_RATED: 'newAndNoteworthy',
  RECENTLY_UPDATED: 'newAndNoteworthy',
  ASSISTED_LIVING: 'seniorLiving',
  INDEPENDENT_LIVING: 'seniorLiving',
  SENIOR_LIVING: 'seniorLiving',
}

export type RefinementSlug = keyof typeof refinementMap

export const refinementSlugs = Object.keys(refinementMap)

export const refinementSlugsNotToIndex = refinementSlugs.filter(
  (slug) => refinementMap[slug].noIndex
)

export const propertyTypesNotIndexedNotFollow = [
  propertyTypeSlugMap[PropertyTypeFilter.Townhouses],
  propertyTypeSlugMap[PropertyTypeFilter.Houses],
]
