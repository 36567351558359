export type LeadCookieData = {
  name: string
  email: string
  phone: string
  moveDate: string
}

// TODO: Remove this when we kill rent next. (Assuming we're not re-writing again by then...)
export class LeadCookieParser {
  /**
   * Convert lead cookie string into an object
   */
  static parse(leadCookieStr: string): LeadCookieData {
    // prettier-ignore
    // eg:`||email|phone|||||moveDate||||||||||name`
    const [, , email, phone, , , , , moveDate, , , , , , , , , , name] = leadCookieStr.split('|')
    return {
      email,
      moveDate: formatMoveDateString(moveDate) || '',
      name,
      phone,
    }
  }

  /**
   * Convert lead cookie object into a string
   */
  static stringify({ email, phone, moveDate, name }: LeadCookieData): string {
    return [
      undefined,
      undefined,
      email,
      phone,
      undefined,
      undefined,
      undefined,
      undefined,
      moveDate,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      name,
    ].join('|')
  }
}

/**
 * If `rent-js` has set the cookie, it's value will be in
 * this format: `MM/DD/YYYY`, in which case, we need to reformat
 * to `YYYY-MM-DD` for our current lead form to parse it correctly.
 *
 * If `rent` has set the cookie, `rent-js` is able to read the
 * `YYYY-MM-DD` format.
 *
 * @todo Remove once `rent-js` is dead (consider session storage at that point)
 * @param {String} [moveDate]
 */
function formatMoveDateString(moveDate: string) {
  if (moveDate.includes('/')) {
    const [mm, dd, yyyy] = moveDate.split('/')
    return [yyyy, mm, dd].join('-')
  }
  return moveDate
}
