'use client'

import clsx from 'clsx'
import { AuthModalTriggers } from '../../user/auth-modals/auth-modal-triggers'
import { LogOutButton } from '../../user/log-out-button'
import { useUser } from '../../user/user.store'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'

const testId = 'menu-section'

export function DrawerMenuUserLinks(props: {
  animationDuration: number
  handleClose: () => void
}) {
  const user = useUser()

  return user?.id && user.givenName ? (
    <>
      <li className={styles.drawerMenuDivider}>
        <span
          className={clsx(
            styles.drawerMenuItem,
            styles.drawerMenuProfileName,
            styles.drawerMenuNonLink
          )}
        >
          {user.givenName}
        </span>
      </li>

      <li>
        <a
          href="/profile"
          className={styles.drawerMenuItem}
          data-tid={`${testId}-profile-link`}
        >
          Profile
        </a>
      </li>

      <li>
        <LogOutButton
          className={styles.drawerMenuItem}
          data-tid={`${testId}-sign-out-btn`}
          onClick={props.handleClose}
        >
          Log Out
        </LogOutButton>
      </li>
    </>
  ) : (
    <AuthModalTriggers
      delay={props.animationDuration}
      logInLinkClassName={styles.drawerMenuItem}
      signUpLinkClassName={styles.drawerMenuItem}
      onLoginClick={props.handleClose}
      onSignUpClick={props.handleClose}
      logInDataTid="menu-section-log-in"
      signUpDataTid="menu-section-sign-up"
    />
  )
}
