/**
 * Lower cases the string and replaces the beginning of each word with the same letter in uppercase
 * @param ignore - ignores the list of words you pass in. It will *not* ignore the any of the words if they are the first word in the string.
 */
export function titlecase(str: string, ignore?: string[]): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index !== 0 && ignore && ignore.includes(word)) {
        return word
      }
      return word.charAt(0).toUpperCase() + word.substring(1)
    })
    .join(' ')
}
