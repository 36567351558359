'use client'

import Link from 'next/link'
import styles from './page-header.module.css'
import { useOpenModal } from '../../../../features/modals/use-open-modal'
import { ModalName } from '../../../../features/modals/modals.config'
import { useRouter } from 'next/navigation'
import {
  useIsLoggedIn,
  userTotalSavedSearches,
} from '../../../../features/user/user.store'
import { useAtomValue } from 'jotai'

export function PageHeaderSavedSearchesLink() {
  const isLoggedIn = useIsLoggedIn()
  const openModal = useOpenModal()
  const router = useRouter()
  const savedSearchesCount = useAtomValue(userTotalSavedSearches)

  return (
    <Link
      prefetch={false}
      href="/saved-searches"
      className={styles.pageHeaderLink}
      onClick={(e) => {
        if (!isLoggedIn) {
          e.preventDefault()
          openModal({
            id: ModalName.SIGN_IN,
            props: {
              onSuccess: () => router.push('/saved-searches'),
              isRenterOnlyLogin: true,
            },
          })
        }
      }}
      data-tid="saved-searches"
      data-tag_item="saved_searches"
    >
      <span>
        Saved Searches{' '}
        {isLoggedIn && savedSearchesCount > 0 && (
          <span
            data-tid="saved-search-count"
            className={styles.drawerMenuCount}
          >
            ({savedSearchesCount})
          </span>
        )}
      </span>
    </Link>
  )
}
