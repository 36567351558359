const propertyTypeSlug =
  ':propertyTypes(apartments|condos|houses|townhomes|(?:apartments|condos|houses|townhomes){1}(?:_apartments|_condos|_houses|_townhomes){1,3})'

const searchPaths = [
  `/zip/:zipCode-${propertyTypeSlug}-For-Rent/:refinements?/`,
  `/p/${propertyTypeSlug}/:state/:location/:poi/:refinements?/`,
  `/${propertyTypeSlug}/schools/:state/districts/:schoolDistrict/:refinements?/`,
  `/${propertyTypeSlug}/schools/:state/:location/:school/:refinements?/`,
  `/neighborhoods/${propertyTypeSlug}/:state/:location/:hood/:refinements?/`,
  `/${propertyTypeSlug}/counties/:state/:county/:refinements?/`,
  `/${propertyTypeSlug}/:state/:location/:refinements?/`,
]

module.exports = { searchPaths, propertyTypeSlug }
