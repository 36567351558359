'use client'

import { useCallback, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { flushSync } from 'react-dom'

type ForceUpdateFn = () => void

/**
 * Hook replacement for `forceRender` behavior.
 * Useful for manually triggering renders.
 *
 * @returns {function} - A function that when called will trigger a render.
 */
export function useForceUpdate(): ForceUpdateFn {
  const [, forceUpdate] = useState(false)

  return useCallback(() => {
    flushSync(() => {
      forceUpdate((s) => !s)
    })
  }, [])
}
