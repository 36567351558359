'use client'

import { useEffect, useRef } from 'react'

export function useIdleCallback(callback: () => void, timeout?: number) {
  const handle = useRef<number | null>(null)

  useEffect(() => {
    if ('requestIdleCallback' in window) {
      handle.current = requestIdleCallback(
        (deadline: IdleDeadline) => {
          let hasRun = false
          while (
            hasRun === false &&
            (deadline.timeRemaining() > 0 || deadline.didTimeout)
          ) {
            hasRun = true
            callback()
          }
        },
        { timeout } // If set, it will run the callback after the specified amount of time has elapsed, even if the page is still busy
      )
    } else if ('requestAnimationFrame' in window) {
      handle.current = requestAnimationFrame(() => {
        callback()
      })
    } else {
      callback()
    }

    return () => {
      const { current: currentHandle } = handle
      handle.current = null

      if (currentHandle == null) {
        return
      }

      if ('cancelIdleCallback' in window) {
        window.cancelIdleCallback(currentHandle)
      }
      if ('cancelAnimationFrame' in window) {
        window.cancelAnimationFrame(currentHandle)
      }
    }
  }, [callback, timeout])
}
