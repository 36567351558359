import { propertyTypeRefinementMap } from '@brand/search/refinements'
import type { LocationSearchResult } from '../../../__generated__/api-types'
import { LocationSearchTypes } from '../../../__generated__/api-types'
import { getSearchPageUrl } from './get-search-page-url'
import type { SearchParams } from '../search-page.types'

type GetSearchPageUrlFromLocationParams = {
  location: Partial<LocationSearchResult>
  refinements?: string | string[]
  propertyTypes?: string | string[]
  singlePropertyTypeFilterParam?: string | string[] // i.e. '?property-type=houses'
  moveInDate?: SearchParams['move_in_date']
  pathName?: string | null
  sortByDistance?: boolean
  userLatLng?: SearchParams['userLatLng']
  display?: string
}

/** Takes a Location and returns the search URL. */
export function getSearchPageUrlFromLocation({
  location,
  refinements,
  propertyTypes = 'apartments',
  singlePropertyTypeFilterParam,
  moveInDate,
  pathName,
  sortByDistance,
  userLatLng,
  display,
}: GetSearchPageUrlFromLocationParams) {
  if (!location.type || !location.seoPath) {
    return ''
  }

  const chunks = location.seoPath
    .replace(new RegExp(Object.keys(propertyTypeRefinementMap).join('|')), '')
    .split('/')
    .filter(Boolean)

  let params: Record<string, string | string[]> = {}

  switch (location.type) {
    case LocationSearchTypes.State:
      params = {
        propertyTypes,
        state: chunks[0],
      }
      break
    case LocationSearchTypes.City:
    case LocationSearchTypes.College:
    case LocationSearchTypes.Military:
      params = {
        propertyTypes,
        state: chunks[0],
        location: chunks[1],
      }
      break
    case LocationSearchTypes.Hood:
      params = {
        propertyTypes,
        state: chunks[1],
        location: chunks[2],
        hood: chunks[3],
      }
      break
    case LocationSearchTypes.School:
      const schoolLocation = chunks.filter((item) => item !== 'schools') // remove 'schools' from the array
      params = {
        propertyTypes,
        state: schoolLocation[0],
        location: schoolLocation[1],
        school: schoolLocation[2],
      }
      break
    case LocationSearchTypes.District:
      const schoolDistrictLocation = chunks.filter(
        (item) => item !== 'schools' && item !== 'districts'
      ) // remove 'schools' and 'districts' from the array
      params = {
        propertyTypes,
        state: schoolDistrictLocation[0],
        schoolDistrict: schoolDistrictLocation[1],
      }
      break
    case LocationSearchTypes.Poi:
      params = {
        propertyTypes,
        state: chunks[1],
        location: chunks[2],
        poi: chunks[3],
      }
      break
    case LocationSearchTypes.County:
      const countyLocation = chunks.filter((item) => item !== 'counties') // remove 'counties' from the array
      params = {
        propertyTypes,
        state: countyLocation[0],
        county: countyLocation[1],
      }
      break
    case LocationSearchTypes.Zip:
      if (location.id) {
        params = {
          propertyTypes,
          zipCode: location.id,
        }
      }
      break
  }

  if (singlePropertyTypeFilterParam) {
    params['property-type'] = singlePropertyTypeFilterParam
  }

  if (refinements) {
    params.refinements = refinements
  }

  if (moveInDate) {
    params['move_in_date'] = moveInDate
  }

  if (userLatLng) {
    params.userLatLng = userLatLng
  }

  if (location?.bbox) {
    params['bbox'] = location?.bbox.join(',')
  }

  if (pathName === '/') {
    params['source'] = 'homepage'
  }

  if (sortByDistance) {
    params['sortByDistance'] = 'true'
  }

  if (display) {
    params.display = display
  }

  return getSearchPageUrl(params)
}
