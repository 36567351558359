'use client'

import { ReactComponent as HeartFilledIcon } from '@brand/icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '@brand/icons/heart.svg'
import { ReactComponent as BookmarkIcon } from '@brand/icons/bookmark-empty.svg'
import { ReactComponent as BookmarkFilledIcon } from '@brand/icons/bookmark-filled.svg'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { userTotalSavedListings } from '../user/user.store'
import styles from '@brand/slots/page-header/page-header.module.css'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

export function PageHeaderSavedPageLink({
  mobileContent,
  renderNewNavLinksDesign,
}: {
  mobileContent?: ReactNode
  renderNewNavLinksDesign?: boolean
}) {
  const isBookmarkIconEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkIconEnabled'],
    false
  )

  const favoritesCount = useAtomValue(userTotalSavedListings)
  const hasFavorites = favoritesCount > 0
  const favoriteIcon = useMemo(() => {
    const FilledIcon = isBookmarkIconEnabled
      ? BookmarkFilledIcon
      : HeartFilledIcon
    const EmptyIcon = isBookmarkIconEnabled ? BookmarkIcon : HeartIcon

    return hasFavorites ? (
      <FilledIcon className={styles.pageHeaderFavoriteFilledIcon} aria-hidden />
    ) : (
      <EmptyIcon aria-hidden data-tid="empty-heart" />
    )
  }, [hasFavorites, isBookmarkIconEnabled])

  return (
    <a
      className={clsx(
        styles.pageHeaderFavoriteLink,
        mobileContent && styles.pageHeaderHiddenWithMobileContent,
        renderNewNavLinksDesign && styles.pageHeaderLink
      )}
      aria-label="Saved properties"
      href="/saved"
      data-tag_item="favorites_icon_header"
      data-tid="favorites-icon-btn"
    >
      {renderNewNavLinksDesign ? (
        <span className={styles.pageHeaderSavedApts}>
          Saved Apts{' '}
          <span className={styles.pageHeaderCount}>
            {hasFavorites && `(${favoritesCount})`}
          </span>
        </span>
      ) : (
        <>
          {favoriteIcon}
          {hasFavorites && (
            <span className={styles.pageHeaderCount}>{favoritesCount}</span>
          )}
        </>
      )}
    </a>
  )
}
