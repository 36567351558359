export function getLocalStorage(key: string, defaultReturn?: string) {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return defaultReturn
  }
}

export function setLocalStorage(
  key: string,
  value: string,
  defaultReturn?: (e: unknown) => void
) {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    if (defaultReturn) {
      defaultReturn(e)
    }
  }
}

export function removeLocalStorage(
  key: string,
  defaultReturn?: (e: unknown) => void
) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    if (defaultReturn) {
      defaultReturn(e)
    }
  }
}
