import { bathRefinementMap, bedRefinementMap } from '@brand/search/refinements'
import type { RefinementMap } from '../search-page.types'

export function getBedBathFilteredRefinements(
  parsedRefinementsMap: RefinementMap
) {
  const hasAllBeds = Object.keys(bedRefinementMap).every((bed) =>
    Object.keys(parsedRefinementsMap)?.includes(bed)
  )
  const hasAllBaths = Object.keys(bathRefinementMap).every((bath) =>
    Object.keys(parsedRefinementsMap)?.includes(bath)
  )

  return Object.keys(parsedRefinementsMap).reduce<RefinementMap>(
    (acc, refinementKey) => {
      const shouldExcludeBed = hasAllBeds && bedRefinementMap[refinementKey]
      const shouldExcludeBath = hasAllBaths && bathRefinementMap[refinementKey]

      if (!shouldExcludeBed && !shouldExcludeBath) {
        acc[refinementKey] = parsedRefinementsMap[refinementKey]
      }

      return acc
    },
    {}
  )
}
