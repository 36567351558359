import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from '@rentpath/core-components'
import { waitFor } from '@rentpath/web-utils'
import type { ElementType, ForwardedRef } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const focusTrapDefaultElement = 'div'

type FocusTrapOwnProps = {
  children: React.ReactNode
  enabled?: boolean
  timeout?: number
}

export type FocusTrapProps<
  T extends React.ElementType = typeof focusTrapDefaultElement
> = PolymorphicPropsWithRef<FocusTrapOwnProps, T>

export const FocusTrap: PolymorphicForwardRefExoticComponent<
  FocusTrapOwnProps,
  typeof focusTrapDefaultElement
> = forwardRef(function FocusTrap<
  T extends ElementType = typeof focusTrapDefaultElement
>(
  {
    as,
    enabled,
    timeout,
    ...restProps
  }: PolymorphicPropsWithoutRef<FocusTrapOwnProps, T>,
  forwardedRef: ForwardedRef<HTMLElement>
) {
  const Element: React.ElementType = as || focusTrapDefaultElement
  const ref = useFocusTrap({ enabled, timeout })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(forwardedRef, () => ref.current!)

  return <Element ref={ref} {...restProps} />
})

export function useFocusTrap({
  enabled = false,
  timeout,
}: Omit<FocusTrapOwnProps, 'children'>) {
  const ref = useRef<HTMLDivElement>(null)
  const lastRef = useRef<HTMLElement>()

  useEffect(
    function initializeFocusTrap() {
      if (!enabled) {
        return
      }

      const el = ref.current

      // store reference to last focused element
      if (document.activeElement) {
        lastRef.current = document.activeElement as HTMLElement
      }

      async function handleMount() {
        if (!el || !enabled) {
          return
        }

        if (timeout) {
          await waitFor(timeout)
        }

        const firstFocusable = el.querySelector('button') as HTMLElement | null
        if (firstFocusable) {
          firstFocusable.focus()
        }

        el?.addEventListener('keydown', handleKeyDown)
      }

      function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
          const focusable = el?.querySelectorAll(
            focusableElements
          ) as NodeListOf<HTMLElement>
          const firstFocusable = focusable[0]
          const lastFocusable = focusable[focusable.length - 1]

          if (event.shiftKey) {
            if (document.activeElement === firstFocusable) {
              lastFocusable.focus()
              event.preventDefault()
            }
          } else {
            if (document.activeElement === lastFocusable) {
              firstFocusable.focus()
              event.preventDefault()
            }
          }
        }
      }

      function handleCleanUpOnUnmount() {
        if (!enabled) {
          return
        }

        if (lastRef.current) {
          lastRef.current.focus()
          lastRef.current = undefined
        } else {
          const firstFocusable = el?.querySelector('button')
          if (firstFocusable) {
            firstFocusable.blur()
          }
        }

        el?.removeEventListener('keydown', handleKeyDown)
      }

      void handleMount()

      return handleCleanUpOnUnmount
    },
    [enabled, timeout]
  )

  return ref
}
