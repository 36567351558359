// eslint-disable-next-line @typescript-eslint/no-var-requires
const { searchPaths } = require('./search-paths-cjs')

export const poiSearchPaths = [searchPaths[1]]

export const schoolDistrictSearchPaths = [searchPaths[2]]

export const schoolSearchPaths = [searchPaths[3]]

export const hoodSearchPaths = [searchPaths[4]]

export const citySearchPaths = [searchPaths[6]]

export const zipSearchPaths = [searchPaths[0]]

export const countySearchPaths = [searchPaths[5]]
