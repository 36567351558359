'use client'

import { useEffect } from 'react'

/**
 * This hook attaches a listener to the window. You should only be using this if you need
 * that functionality.  An example of this would be listening for escape on modal.
 *
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export function useWindowKeyPress(key: string, action: () => void): void {
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) {
        action()
      }
    }
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
  }, [action, key])
}
