'use client'

import { deepEqual } from 'fast-equals'
import type { EffectCallback } from 'react'
import { useEffect, useRef } from 'react'

function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>()

  if (!deepEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepCompareEffect<T = unknown>(
  callback: EffectCallback,
  dependencies: T[]
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
