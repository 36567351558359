import format from 'date-fns/format'

export const dateOnly = (
  date: string | null | undefined,
  dateFormat: string
) => {
  if (!date || !Date.parse(date)) return null

  const isoDate = new Date(date)
  // Date needs to be transformed by using Timezone offset to get proper date only, if not doing it it will return previous day
  const dateWithoutOffset = new Date(
    isoDate.valueOf() + isoDate.getTimezoneOffset() * 60 * 1000
  )

  return format(dateWithoutOffset, dateFormat)
}
