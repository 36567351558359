'use client'

import * as React from 'react'

type TOptimisticUIStateActionReturnValue<T> = T | undefined | null | void
export type TOptimisticUIStateAction<T> = (
  state: T
) => Promise<TOptimisticUIStateActionReturnValue<T>>
export type TOptimisticStateOptions<T> = {
  initialState: T
  successState: (state: T) => T
  errorState?: (state: T) => T
}
type TOptimisticUIState<T> = {
  current: T
  loading: boolean
  error: null | Error
}

export function useOptimisticState<T>(
  options: TOptimisticStateOptions<T>
): [
  TOptimisticUIState<T>,
  (
    fn: TOptimisticUIStateAction<T>
  ) => Promise<TOptimisticUIStateActionReturnValue<T>>
] {
  const opUIState: TOptimisticUIState<T> = {
    loading: false,
    current: options.initialState as T,
    error: null,
  }
  const [state, setState] = React.useState(opUIState)

  async function runAction(fn: TOptimisticUIStateAction<T>) {
    let result: TOptimisticUIStateActionReturnValue<T>

    setState({
      loading: true,
      current: options.successState(state.current) as T,
      error: null,
    })

    try {
      result = await fn(state.current)
      setState({
        loading: false,
        current:
          result !== undefined && result !== null
            ? result
            : options.successState(state.current as T),
        error: null,
      })
    } catch (error) {
      setState({
        loading: false,
        current: (options.errorState
          ? options.errorState(state.current as T)
          : options.initialState) as T,
        error: error as unknown as Error,
      })
    }

    return result
  }

  return [state, runAction]
}
