export const PROPERTY_TYPES_SEPARATOR = '_'
export const REFINEMENT_SEPARATOR = '-'
export const MIN_PRICE_PREFIX = 'from-'
export const MAX_PRICE_PREFIX = 'under-'
export const BEDROOM_SUFFIX = '-beds'
export const BATHROOM_SUFFIX = '-baths'
export const STUDIO_SLUG = '0-beds'
export const CLEAR_ALL_TEXT = 'Reset All'
export const DEFAULT_SORT_KEY = 'recommended'
export const DEFAULT_SORT_TEXT = 'Recommended'
