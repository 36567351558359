/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserEventMutationVariables = Types.Exact<{
  input: Types.UserEventInput;
}>;


export type UserEventMutation = { userEvent: { facebookResponse: { success: boolean, message?: string | null, meta?: Record<string, unknown> | null }, tiktokResponse?: { success: boolean, message?: string | null, meta?: Record<string, unknown> | null } | null } };


export const UserEventDocument = `
    mutation UserEvent($input: UserEventInput!) {
  userEvent(input: $input) {
    facebookResponse {
      success
      message
      meta
    }
    tiktokResponse {
      success
      message
      meta
    }
  }
}
    `;

    export function createUserEventFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserEvent(variables: UserEventMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserEventMutation, UserEventMutationVariables>(UserEventDocument, variables, headers);
      }
    }

