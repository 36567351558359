'use client'

import { useEffect, useState } from 'react'

/**
 * Hook replacement for `componentDidMount` behavior.
 * Useful for client-only rendering scenarios.
 *
 * @returns {boolean} - A boolean that will be true once the
 * component has mounted in the client.
 */
export function useHasMounted(): boolean {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}
