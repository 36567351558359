import type { ComponentType, HTMLAttributes } from 'react'

const brRegex = /<br\s?\/?>/gi

export function handleBR(
  terms: string,
  Component: ComponentType<HTMLAttributes<HTMLElement>> | string = 'div'
) {
  return terms
    .split(brRegex)
    .filter(Boolean)
    .map((string, i) => {
      const s = string.trim()
      return <Component key={`${s}-${i}`}>{s}</Component>
    })
}
