import {
  citySearchPaths,
  countySearchPaths,
  hoodSearchPaths,
  poiSearchPaths,
  schoolDistrictSearchPaths,
  schoolSearchPaths,
  zipSearchPaths,
} from '@brand/search/search-paths'
import type { MatchFunction, PathFunction } from 'path-to-regexp'
import { compile, match } from 'path-to-regexp'
import type {
  CitySearchParams,
  CountySearchParams,
  HoodSearchParams,
  PoiSearchParams,
  SchoolDistrictSearchParams,
  SchoolSearchParams,
  SearchParams,
  SearchPathParams,
  SearchType,
  ZipSearchParams,
} from './search-page.types'

type SearchPathConfig = {
  citySearchPaths: string[]
  countySearchPaths: string[]
  hoodSearchPaths: string[]
  poiSearchPaths: string[]
  schoolDistrictSearchPaths: string[]
  schoolSearchPaths: string[]
  zipSearchPaths: string[]
}

export const multiFourBedroomPath = '-4-beds'

export class SearchRouteUtils {
  private poiSearchCompilers: PathFunction<PoiSearchParams>[] = []
  private citySearchCompilers: PathFunction<CitySearchParams>[] = []
  private countySearchCompilers: PathFunction<CountySearchParams>[] = []
  private hoodSearchCompilers: PathFunction<HoodSearchParams>[] = []
  private schoolSearchCompilers: PathFunction<SchoolSearchParams>[] = []
  private schoolDistrictSearchCompilers: PathFunction<SchoolDistrictSearchParams>[] =
    []
  private zipSearchCompilers: PathFunction<ZipSearchParams>[] = []

  private poiSearchMatchers: MatchFunction<PoiSearchParams>[] = []
  private citySearchMatchers: MatchFunction<CitySearchParams>[] = []
  private countySearchMatchers: MatchFunction<CountySearchParams>[] = []
  private hoodSearchMatchers: MatchFunction<HoodSearchParams>[] = []
  private schoolSearchMatchers: MatchFunction<SchoolSearchParams>[] = []
  private schoolDistrictSearchMatchers: MatchFunction<SchoolDistrictSearchParams>[] =
    []
  private zipSearchMatchers: MatchFunction<ZipSearchParams>[] = []

  private searchPathConfig: SearchPathConfig

  constructor(searchPathConfig: SearchPathConfig) {
    this.searchPathConfig = searchPathConfig

    for (const path of this.searchPathConfig.poiSearchPaths) {
      this.poiSearchCompilers.push(compile<PoiSearchParams>(path))
      this.poiSearchMatchers.push(match<PoiSearchParams>(path))
    }

    for (const path of this.searchPathConfig.citySearchPaths) {
      this.citySearchCompilers.push(compile<CitySearchParams>(path))
      this.citySearchMatchers.push(match<CitySearchParams>(path))
    }

    for (const path of this.searchPathConfig.countySearchPaths) {
      this.countySearchCompilers.push(compile<CountySearchParams>(path))
      this.countySearchMatchers.push(match<CountySearchParams>(path))
    }

    for (const path of this.searchPathConfig.hoodSearchPaths) {
      this.hoodSearchCompilers.push(compile<HoodSearchParams>(path))
      this.hoodSearchMatchers.push(match<HoodSearchParams>(path))
    }

    for (const path of this.searchPathConfig.schoolSearchPaths) {
      this.schoolSearchCompilers.push(compile<SchoolSearchParams>(path))
      this.schoolSearchMatchers.push(match<SchoolSearchParams>(path))
    }

    for (const path of this.searchPathConfig.schoolDistrictSearchPaths) {
      this.schoolDistrictSearchCompilers.push(
        compile<SchoolDistrictSearchParams>(path)
      )
      this.schoolDistrictSearchMatchers.push(
        match<SchoolDistrictSearchParams>(path)
      )
    }

    for (const path of this.searchPathConfig.zipSearchPaths) {
      this.zipSearchCompilers.push(compile<ZipSearchParams>(path))
      this.zipSearchMatchers.push(match<ZipSearchParams>(path))
    }
  }

  public getSearchPathForParams(params: SearchPathParams): string | undefined {
    if (
      this.isCitySearchParams(params) &&
      params.location &&
      params.propertyTypes &&
      params.state
    ) {
      return this.maybeCompile<CitySearchParams>(
        params,
        this.citySearchCompilers
      )
    }

    if (this.isPoiSearchParams(params)) {
      return this.maybeCompile<PoiSearchParams>(params, this.poiSearchCompilers)
    }

    if (this.isSchoolDistrictSearchParams(params)) {
      return this.maybeCompile<SchoolDistrictSearchParams>(
        params,
        this.schoolDistrictSearchCompilers
      )
    }

    if (this.isSchoolSearchParams(params)) {
      return this.maybeCompile<SchoolSearchParams>(
        params,
        this.schoolSearchCompilers
      )
    }

    if (this.isHoodSearchParams(params)) {
      return this.maybeCompile<HoodSearchParams>(
        params,
        this.hoodSearchCompilers
      )
    }

    if (this.isZipSearchParams(params)) {
      return this.maybeCompile<ZipSearchParams>(params, this.zipSearchCompilers)
    }

    if (this.isCountySearchParams(params)) {
      return this.maybeCompile<CountySearchParams>(
        params,
        this.countySearchCompilers
      )
    }

    throw new Error('Invalid search params')
  }

  public getSearchParamsForPath(path: string): SearchPathParams {
    let result: SearchPathParams | undefined

    if (this.isPoiSearchPath(path)) {
      result = this.maybeMatch(path, this.poiSearchMatchers)
    } else if (this.isSchoolDistrictSearchPath(path)) {
      result = this.maybeMatch(path, this.schoolDistrictSearchMatchers)
    } else if (this.isSchoolSearchPath(path)) {
      result = this.maybeMatch(path, this.schoolSearchMatchers)
    } else if (this.isHoodSearchPath(path)) {
      result = this.maybeMatch(path, this.hoodSearchMatchers)
    } else if (this.isZipSearchPath(path)) {
      result = this.maybeMatch(path, this.zipSearchMatchers)
    } else if (this.isCountySearchPath(path)) {
      result = this.maybeMatch(path, this.countySearchMatchers)
    } else if (this.isCitySearchPath(path)) {
      result = this.maybeMatch(path, this.citySearchMatchers)
    }

    if (result) {
      return result
    }

    throw new Error('Invalid search path')
  }

  public getSearchTypeForPath(path: string): SearchType | null {
    const pathWithoutHash = path.split('#')[0]
    const pathWithoutQuestionMark = pathWithoutHash.split('?')[0]

    // these are ordered from most specific to least specific. please do not change these as it can cause paths to find an incorrect search type
    if (this.isHoodSearchPath(pathWithoutQuestionMark)) return 'hood'
    if (this.isPoiSearchPath(pathWithoutQuestionMark)) return 'poi'
    if (this.isZipSearchPath(pathWithoutQuestionMark)) return 'zip'
    if (this.isSchoolDistrictSearchPath(pathWithoutQuestionMark))
      return 'schoolDistrict'
    if (this.isSchoolSearchPath(pathWithoutQuestionMark)) return 'school'
    if (this.isCountySearchPath(pathWithoutQuestionMark)) return 'county'
    if (this.isCitySearchPath(pathWithoutQuestionMark)) return 'city'

    throw new Error('Invalid search params')
  }

  public getSearchTypeForParams(params: SearchPathParams): SearchType | null {
    // these are ordered from most specific to least specific. please do not change these as it can cause paths to find an incorrect search type
    if (this.isHoodSearchParams(params)) return 'hood'
    if (this.isPoiSearchParams(params)) return 'poi'
    if (this.isZipSearchParams(params)) return 'zip'
    if (this.isSchoolDistrictSearchParams(params)) return 'schoolDistrict'
    if (this.isSchoolSearchParams(params)) return 'school'
    if (this.isCountySearchParams(params)) return 'county'
    if (this.isCitySearchParams(params)) return 'city'

    throw new Error('Invalid search params')
  }

  public isPoiSearchPath(
    path: string
  ): path is typeof this.searchPathConfig.poiSearchPaths[number] {
    return path.startsWith('/p/')
  }

  public isPoiSearchParams(
    params: SearchPathParams
  ): params is PoiSearchParams {
    return 'poi' in params
  }

  public isSchoolDistrictSearchPath(
    path: string
  ): path is typeof this.searchPathConfig.schoolDistrictSearchPaths[number] {
    return this.searchPathConfig.schoolDistrictSearchPaths.some(
      (schoolDistrictSearchPath) => match(schoolDistrictSearchPath)(path)
    )
  }

  public isSchoolDistrictSearchParams(
    params: SearchPathParams
  ): params is SchoolDistrictSearchParams {
    return 'schoolDistrict' in params
  }

  public isSchoolSearchPath(
    path: string
  ): path is typeof this.searchPathConfig.schoolSearchPaths[number] {
    return this.searchPathConfig.schoolSearchPaths.some((schoolSearchPath) =>
      match(schoolSearchPath)(path)
    )
  }

  public isSchoolSearchParams(
    params: SearchPathParams
  ): params is SchoolSearchParams {
    return 'school' in params
  }

  public isHoodSearchPath(path: string) {
    const pathWithoutParams = path.split('?')[0]

    return this.searchPathConfig.hoodSearchPaths.some((hoodSearchPath) =>
      match(hoodSearchPath)(pathWithoutParams)
    )
  }

  public isHoodSearchParams(
    params: SearchPathParams
  ): params is HoodSearchParams {
    return 'hood' in params
  }

  public isZipSearchPath(
    path: string
  ): path is typeof this.searchPathConfig.zipSearchPaths[number] {
    return this.searchPathConfig.zipSearchPaths.some((zipSearchPath) => {
      const matcher = match<ZipSearchParams>(zipSearchPath)
      const matched = matcher(path)

      return matched && matched.params.zipCode.length === 5
    })
  }

  public isZipSearchParams(
    params: SearchPathParams
  ): params is ZipSearchParams {
    return 'zipCode' in params
  }

  public isCitySearchPath(
    path: string
  ): path is typeof this.searchPathConfig.citySearchPaths[number] {
    return (
      !path.replace(multiFourBedroomPath, '').includes('-4-') &&
      this.searchPathConfig.citySearchPaths.some((citySearchPath) =>
        match(citySearchPath)(path)
      )
    )
  }

  public isCitySearchParams(
    params: SearchPathParams
  ): params is CitySearchParams {
    return (
      !this.isPoiSearchParams(params) &&
      !this.isSchoolDistrictSearchParams(params) &&
      !this.isHoodSearchParams(params) &&
      !this.isSchoolSearchParams(params) &&
      !this.isZipSearchParams(params) &&
      !this.isCountySearchParams(params)
    )
  }

  public isCountySearchPath(
    path: string
  ): path is typeof this.searchPathConfig.countySearchPaths[number] {
    return this.searchPathConfig.countySearchPaths.some((countySearchPath) =>
      match(countySearchPath)(path)
    )
  }

  public isCountySearchParams(
    params: SearchPathParams
  ): params is CountySearchParams {
    return 'county' in params
  }

  private maybeCompile<T extends object>(
    params: T,
    compilers: PathFunction<T>[]
  ) {
    const compiler = compilers.find((c) => {
      try {
        return c(params)
      } catch (e) {}
    })

    return compiler?.(params)
  }

  private maybeMatch<T extends object>(
    path: string,
    matchers: MatchFunction<T>[]
  ) {
    const matcher = matchers.find((m) => m(path))
    const result = matcher?.(path)

    return result ? result?.params : undefined
  }

  /**
   * Next tends to lump path params and query params into the same bucket.
   *
   * This plucks out the path params and returns them as SearchPathParamSearchParams
   */
  static queryToSearchParams(
    rawParams: NodeJS.Dict<string | string[] | undefined>
  ): SearchParams {
    const {
      state,
      location,
      poi,
      schoolDistrict,
      school,
      hood,
      zipCode,
      county,
      propertyTypes,
      refinements,
    } = SearchRouteUtils.dictToRecord(rawParams)

    if (poi) {
      return {
        ...rawParams,
        state,
        location,
        poi,
        propertyTypes,
        refinements,
      }
    } else if (schoolDistrict) {
      return {
        ...rawParams,
        state,
        location,
        schoolDistrict,
        propertyTypes,
        refinements,
      }
    } else if (school) {
      return {
        ...rawParams,
        state,
        location,
        school,
        propertyTypes,
        refinements,
      }
    } else if (hood) {
      return {
        ...rawParams,
        state,
        location,
        hood,
        propertyTypes,
        refinements,
      }
    } else if (zipCode) {
      return {
        ...rawParams,
        zipCode,
        propertyTypes,
        refinements,
      }
    } else if (county) {
      return {
        ...rawParams,
        state,
        county,
        propertyTypes,
        refinements,
      }
    } else if (location) {
      return {
        ...rawParams,
        state,
        location,
        propertyTypes,
        refinements,
      }
    }

    throw new Error('Invalid search params')
  }

  static dictToRecord(dict: NodeJS.Dict<string | string[] | undefined>) {
    return Object.entries(dict).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (typeof value === 'string') {
          acc[key] = value
        } else if (Array.isArray(value)) {
          acc[key] = value[0]
        }
        return acc
      },
      {}
    )
  }
}

export const searchRouteUtils = new SearchRouteUtils({
  citySearchPaths,
  countySearchPaths,
  hoodSearchPaths,
  poiSearchPaths,
  schoolDistrictSearchPaths,
  schoolSearchPaths,
  zipSearchPaths,
})
