'use client'

import { useState } from 'react'

import { useInterval } from './use-interval'

export function useRetryUntilResolved(
  callback: { (): boolean; (): unknown },
  interval = 100
) {
  const [hasResolved, setHasResolved] = useState(false)

  useInterval(
    () => {
      const result = callback()
      if (result) {
        setHasResolved(true)
      }
    },
    hasResolved ? null : interval
  )

  return hasResolved
}
