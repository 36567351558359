import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import styles from './back-drop.module.css'

type BackDropProps = {
  isVisible: boolean
} & HTMLAttributes<HTMLDivElement>

export const BackDrop = forwardRef<HTMLDivElement, BackDropProps>(
  function BackDrop({ isVisible, className, ...props }, forwardedRef) {
    return (
      <div
        {...props}
        aria-hidden="true"
        ref={forwardedRef}
        className={clsx(
          styles.backDrop,
          isVisible && styles.backDropVisible,
          className
        )}
      />
    )
  }
)
