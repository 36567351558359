export function pluralize(count: number, text: string): string {
  if (count === 1) {
    return text
  }
  if (text.endsWith('y')) {
    return text.slice(0, -1) + 'ies'
  }
  if (text.endsWith('s')) {
    return text + 'es'
  }
  return text + 's'
}
