'use client'

import { useState } from 'react'

/**
 * It determines what items to show or hide based on the offset
 * It returns the same list with an addition `visible` property
 *
 * @param offset number of items you want to show or hide at a time
 * @param items list of items you want to show or hide
 * @param start which item in the list to start hiding @default offset
 * @param total total number of items in the list @default items.length
 * @example
 * function MyComponent() {
 *   const [items, viewMore, viewLess] = useViewMoreOrLess(3, 10)
 *
 *   return items.map(({ item, visible }) => {
 *     const visibility = visible ? 'visible' : 'hidden'
 *     return <div key={i} style={{ visibility }}>{item}</div>
 *   })
 * }
 *
 * @example
 * function MyComplexComponent() {
 *   const [items, viewMore, viewLess, cursorIndex] = useViewMoreOrLess(3, 10)
 *   const itemsLeft = props.items.length - 1 - cursorIndex
 *   const mod = props.items.length % props.offset
 *
 *   return (
 *     <>
 *       {list.map(({ item, visible }, index) => (
 *         <div key={index} style={{ display: visible ? 'block' : 'none' }}>
 *           {item}
 *         </div>
 *       ))}
 *       {viewLess && (
 *         <button onClick={viewLess}>
 *           Show {itemsLeft === 0 && mod > 0 ? mod : props.offset} Less
 *         </button>
 *       )}
 *       {viewMore && (
 *         <button onClick={viewMore}>
 *           Show {itemsLeft > props.offset ? props.offset : itemsLeft} More
 *         </button>
 *       )}
 *    </>
 *   )
 * }
 *
 * @returns items, viewMore, viewLess
 */
export function useViewMoreOrLess<T>(
  offset: number,
  items: T[],
  start?: number,
  total?: number
): [
  Array<{ visible: boolean; item: T }>,
  null | (() => void),
  null | (() => void),
  number
] {
  const numberOfItems = total || items.length
  const starting = (start || offset) - 1
  // assume offset if no start has been passed
  const [cursorIndex, setCurrent] = useState(starting)

  function viewMore() {
    const index = cursorIndex + offset
    setCurrent(index > numberOfItems - 1 ? numberOfItems - 1 : index)
  }

  function viewLess() {
    if (cursorIndex === numberOfItems - 1) {
      const mod = numberOfItems % offset
      /**
       * if mod is not 0, then use it as the current index
       * if mod is 0, then substract the offset from the total
       */
      setCurrent((mod !== 0 ? numberOfItems - mod : numberOfItems - offset) - 1)
    } else {
      const index = cursorIndex - offset
      /**
       * if the index is less than the starting value, use the starting value
       * so we end up with the original shown. othewise, subtract the offset
       */
      setCurrent(index < starting ? starting : index)
    }
  }

  // return the items list with a visible property
  const visibleItems = items.map((item, i) => ({
    item,
    visible: i <= cursorIndex,
  }))

  return [
    visibleItems,
    // return viewMore the current index is less than the total length - 1
    cursorIndex < numberOfItems - 1 ? viewMore : null,
    // return viewLess if the current index is more than the starting value
    cursorIndex > starting ? viewLess : null,
    cursorIndex,
  ]
}
