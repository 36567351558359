import Cookies from 'js-cookie'
import { forwardRef } from 'react'

import type { Button } from '../../components/button/button'

import type { ComponentProps, MouseEvent } from 'react'
import { ZID_KEY } from './user.const'

export const LogOutButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(function LogOutButton(props, ref) {
  return (
    <button
      {...props}
      ref={ref}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        Cookies.remove(ZID_KEY, { path: '/' })
        props.onClick?.(e)
        location.reload()
        // force client side refresh
        // router.replace(location.pathname)
      }}
    >
      Log Out
    </button>
  )
})
