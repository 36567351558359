import { atom } from 'jotai'
import type { ModalConfiguration } from './modals.config'

export const modalsAtom = atom<ModalConfiguration[]>([])

export const openModalAtom = atom(
  (get) => get(modalsAtom),
  (_, set, modal: ModalConfiguration) => {
    set(modalsAtom, [modal])
  }
)

export const closeModalAtom = atom<
  ModalConfiguration[],
  // TODO: this should be fixed because it's not actually used
  // but some usages are passing in a ModalConfiguration
  [ModalConfiguration | undefined],
  void
>(
  (get) => get(modalsAtom),
  (get, set) => {
    set(modalsAtom, [])
  }
)

// This is currently not possible with HeadlessUI.
// (Luckily, we don't have a need for it, currently.)
//
// Given there _was_ a need for this at one point in
// the past, the store will continue to use an array
// structure and these atoms will be left commented out
// to make it easier in case it comes back.
//
// export const filterModal = (stack: ModalConfig[], id: ModalConfig['id']) =>
//   stack.filter((modal) => modal.id !== id)
//
// export const openStackedModalAtom = atom<ModalConfig[], ModalConfig>(
//   (get) => get(currentModalsAtom),
//   (get, set, modal) => {
//     const stack = get(currentModalsAtom)
//     set(currentModalsAtom, [modal].concat(filterModalConfig(stack, modal.id)))
//   }
// )
// export const closeAllModalsAtom = atom<ModalConfig[], ModalConfig>(
//   (get) => get(currentModalsAtom),
//   (get, set) => {
//     set(currentModalsAtom, [])
//   }
// )
