'use client'

import React from 'react'
import { AuthModalTriggers } from '../../../../features/user/auth-modals/auth-modal-triggers'
import { useUser } from '../../../../features/user/user.store'
import styles from './page-header.module.css'

export function PageHeaderAuthTriggers() {
  const user = useUser()

  return !user?.id ? (
    <ul>
      <AuthModalTriggers
        delay={0}
        wrapperSignUpClassName={styles.pageHeaderSignupLink}
        wrapperClassName={styles.pageHeaderAuthLinksWrapper}
        logInLinkClassName={styles.pageHeaderLoginLink}
        logInDataTid="nav-bar-log-in"
        signUpDataTid="nav-bar-sign-up"
        hideSignUp
      />
    </ul>
  ) : null
}
