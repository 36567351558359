/**
 * Map used to compare in `.sort()`.
 *
 * @example
 * {
 *   someSlug: 1,
 *   anotherSlug: 1,
 *   andAnotherSlug 2,
 *   orAnotherSlug 3,
 * }
 */
import type { RefinementSlug } from '@brand/search/refinements'
import { refinementSlugs } from '@brand/search/refinements'

const refinementSortConfig = refinementSlugs.reduce<
  Record<RefinementSlug, number>
>((acc, slug, index) => {
  acc[slug] = index + 1

  return acc
}, {})

/**
 * Sorts the refinement string. See the `:refinement` param the SRP rewrite configs.
 */
export const sortRefinementsSlugs = (refinementsSlugs: string[]): string[] => {
  return refinementsSlugs.sort((slugA: string, slugB: string) => {
    const sortIndexA = refinementSortConfig[slugA] || 0
    const sortIndexB = refinementSortConfig[slugB] || 0

    return sortIndexA - sortIndexB
  })
}
