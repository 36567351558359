import { useContext } from 'react'

import { AbTestingContext } from '../abTestingContext'

/**
 * Retrieve the value of a single feature variable from the A/B experiment decisioning
 *
 * @param featureKey the key of the feature flag to which the variable belongs
 * @param variableKey the key of the variable value to be retrieved
 * @param fallback a default value to return if the lookup fails for any reason
 * @returns value of the variable (string, boolean, etc.)
 */
export function useFeatureVariable<
  T extends
    | string
    | boolean
    | number
    | Record<string, string | boolean | number | string[]>
>([featureKey, variableKey]: [string, string], fallback: T): T {
  const abTesting = useContext(AbTestingContext)
  return (abTesting?.featureMap?.[featureKey]?.[variableKey] as T) ?? fallback
}
