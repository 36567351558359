import { atom, useAtomValue } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import type { User_UserFragment } from './__generated__/user-fields.gql'
import { type SavedSearchSummary } from '../saved-searches/saved-search.types'
import { getSavedSearchRedirectUrl } from '../saved-searches/utils/get-saved-search-redirect-url'

export const createSRPUrlFromSavedSearchUrl = (savedUrl: string) => {
  const splitUrl = savedUrl?.split('/saved-search?')

  if (splitUrl?.length > 1) {
    const srpUrl = getSavedSearchRedirectUrl(`?${splitUrl[1]}`)

    if (srpUrl) {
      return srpUrl
    }
  }

  return savedUrl
}

const userAtomBase = atom<User_UserFragment | null>(null)

export const userAtom = atom(
  (get) => get(userAtomBase),
  (_get, set, updatedUser: User_UserFragment) => {
    set(userAtomBase, {
      ...updatedUser,
      savedSearches: updatedUser?.savedSearches?.map((savedSearch) => {
        return {
          ...savedSearch,
          searchUrl: createSRPUrlFromSavedSearchUrl(savedSearch.searchUrl),
        }
      }),
    })
  }
)

export const useUser = () => useAtomValue(userAtom)

const zutronIdAtom = atom((get) => get(userAtom)?.zutronId)

/** Derived atom that gets the `zutronId` out of the User object. */
export const useZutronId = () => useAtomValue(zutronIdAtom)

export const userIsLoggedInAtom = atom(
  (get) => typeof get(userAtom)?.id === 'string'
)

/** Derived atom that determines if we have a logged-in user. */
export const useIsLoggedIn = () => useAtomValue(userIsLoggedInAtom)

export const userSavedListingIds = atom(
  (get) => get(userAtom)?.savedListingsIds ?? [],
  (get, set, updated: string[]) => {
    const user = get(userAtom)
    set(userAtom, {
      zutronId: user?.zutronId as string,
      ...user,
      savedListingsIds: updated,
      savedSearches: user?.savedSearches || [],
    })
  }
)
export const userTotalSavedListings = atom<number>(
  (get) => get(userSavedListingIds).length
)
export const lastSavedListingIdAtom = atomWithStorage<string>(
  'favorited_listing_id',
  '',
  createJSONStorage<string>(() => localStorage)
)

export const userSavedSearches = atom(
  (get) => get(userAtom)?.savedSearches,
  (get, set, updatedSavedSearches: User_UserFragment['savedSearches']) => {
    const user = get(userAtom)
    set(userAtom, {
      ...(user as User_UserFragment),
      savedSearches: updatedSavedSearches,
    })
  }
)
export const userTotalSavedSearches = atom(
  (get) => (get(userSavedSearches) ?? []).length
)

export const userSaveSearchEdit = atom(
  (get) => get(userAtom)?.savedSearches,
  (get, set, updatedSaveSearch: Partial<SavedSearchSummary>) => {
    const savedSearches = get(userAtom)?.savedSearches
    const user = get(userAtom)
    const updatedSavedSearches =
      savedSearches?.reduce<SavedSearchSummary[]>(
        (
          savedSearchesList: SavedSearchSummary[],
          savedSearch: SavedSearchSummary
        ) => {
          if (savedSearch.id === updatedSaveSearch?.id) {
            savedSearchesList.push({
              ...savedSearch,
              ...updatedSaveSearch,
            })
          } else {
            savedSearchesList.push(savedSearch)
          }

          return savedSearchesList
        },
        []
      ) || []

    set(userAtom, {
      ...(user as User_UserFragment),
      savedSearches: updatedSavedSearches,
    })
  }
)
