/**
 * @param element optional because we expect a querySelector call
 * @param yOffset
 * @param target
 * @param behavior
 * Scroll to an element
 * @returns {Promise} A promise that will resolve once scroll ends or at timeout
 * @example
 * scrollToElement(document.querySelector('#myEl'))
 * @example
 * // Change a scrolling div
 * scrollToElement(myRefElementWithinDiv, -10, myScrollingDiv, 'auto')
 */
export function scrollToElement(
  element: HTMLElement | null,
  yOffset = 0,
  target?: Element | HTMLElement | typeof window,
  behavior: ScrollBehavior = 'smooth'
): Promise<void> {
  const SCROLL_TIMEOUT = 2000

  return new Promise((resolve) => {
    if (element) {
      const container = target || window
      const containerTop =
        'scrollY' in container
          ? () => container.scrollY
          : () => container.scrollTop
      const elementPosition = Math.floor(
        element.getBoundingClientRect().top + containerTop() + yOffset
      )

      container.scrollTo({
        left: 0,
        top: elementPosition,
        behavior,
      })

      const scrollTimeout = setTimeout(() => {
        container.removeEventListener('scroll', scrollWatch)
        resolve()
      }, SCROLL_TIMEOUT)

      const scrollWatch = () => {
        if (containerTop() === elementPosition) {
          container.removeEventListener('scroll', scrollWatch)
          clearTimeout(scrollTimeout)
          resolve()
        }
      }

      if (containerTop() === elementPosition) {
        clearTimeout(scrollTimeout)
        resolve()
      } else {
        container.addEventListener('scroll', scrollWatch)
      }
    } else {
      resolve()
    }
  })
}
