import clsx from 'clsx'
import { ModalName } from '../../modals/modals.config'
import { useOpenModal } from '../../modals/use-open-modal'

export type AuthModalTriggersProps = {
  logInLinkClassName?: string
  signUpLinkClassName?: string
  onLoginClick?: () => void
  onSignUpClick?: () => void
  logInDataTid?: string
  signUpDataTid?: string
  wrapperClassName?: string
  wrapperLogInClassName?: string
  wrapperSignUpClassName?: string
  // usage for animation and focus mgmt
  delay?: number
  hideSignUp?: boolean
}

export function AuthModalTriggers(props: AuthModalTriggersProps) {
  const openModal = useOpenModal()

  return (
    <>
      <li className={clsx(props.wrapperClassName, props.wrapperLogInClassName)}>
        <button
          className={props.logInLinkClassName}
          onClick={() => {
            props.onLoginClick?.()
            openModal({
              id: ModalName.SIGN_IN,
              props: {},
            })
          }}
          data-tag_item="log_in"
          data-tid={props.logInDataTid}
          type="button"
        >
          Log In
        </button>
      </li>

      {!props.hideSignUp && (
        <li
          className={clsx(props.wrapperClassName, props.wrapperSignUpClassName)}
        >
          <button
            className={props.signUpLinkClassName}
            onClick={() => {
              props.onSignUpClick?.()
              openModal({
                id: ModalName.SIGN_UP,
                props: {},
              })
            }}
            data-tag_item="sign_up"
            data-tid={props.signUpDataTid}
            type="button"
          >
            Sign Up
          </button>
        </li>
      )}
    </>
  )
}
