import { isArrayOfType } from './is-array-of-type'

export function isSearchHistoryItem(
  value: unknown
): value is { propertyName: string; locationName: string } {
  type LooseSearchHistoryItem = {
    propertyName?: unknown
    locationName?: unknown
  }

  if (typeof value === 'object' && value !== null) {
    const castedValue = value as LooseSearchHistoryItem
    return (
      typeof castedValue.propertyName === 'string' ||
      typeof castedValue.locationName === 'string'
    )
  }

  return false
}

export function getSearchHistory(searchHistoryRaw: string) {
  if (searchHistoryRaw) {
    try {
      const searchHistory = JSON.parse(searchHistoryRaw)

      if (!isArrayOfType(searchHistory, isSearchHistoryItem)) {
        throw Error('Not a valid JSON record')
      }

      return (
        searchHistory?.[0]?.propertyName || searchHistory?.[0]?.locationName
      )
    } catch (e) {
      // error parsing JSON
    }
  }
}
