'use client'

import { useState, useEffect } from 'react'

declare global {
  interface Window {
    MSStream: string
    opera: string
  }
}

export function useDetectKeyboardOpen() {
  const [isKeyboardOpen, setIsKeyboardProbablyOpen] = useState(false)

  // Function to detect device (iOS or Android)
  function detectDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    } else if (/android/i.test(userAgent)) {
      return 'Android'
    }
    return null
  }

  useEffect(() => {
    const detectedDevice = detectDevice()

    if (detectedDevice !== 'iOS' && detectedDevice !== 'Android') {
      return
    }

    const handleFocus = () => {
      setIsKeyboardProbablyOpen(true)
    }

    const handleBlur = () => {
      setIsKeyboardProbablyOpen(false)
    }

    const inputs = document.querySelectorAll('input')
    inputs.forEach((input) => {
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)
    })

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
      })
    }
  }, [])

  return isKeyboardOpen
}
