/* eslint-disable react/jsx-no-target-blank */
import { ReactComponent as ArrowLeftIcon } from '@brand/icons/arrow-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import { getAnimationDuration } from '@rentpath/web-utils'
import clsx from 'clsx'
import { useState } from 'react'
import { FocusTrap } from '../../../components/focus-trap/focus-trap'
import { IconButton } from '../../../components/icon-button/icon-button'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'

import type { HamburgerMenuLink } from 'types'

type DrawerMenuSubMenuProps = {
  links: Record<string, HamburgerMenuLink[] | null>
  isSubMenuOpen: boolean
  selectedSubmenu?: string
  handleOpenSubMenu: (key: string) => void
  onSubMenuClose: () => void
}

export function DrawerMenuSubMenu(props: DrawerMenuSubMenuProps) {
  const [animationDuration, setAnimationDuration] = useState(0)
  return (
    <>
      {Object.keys(props.links || {}).map((linkKey, i) => {
        const links = props.links[linkKey]
        const isActive =
          props.isSubMenuOpen && props.selectedSubmenu === linkKey
        const shouldTrapFocus =
          Boolean(props.isSubMenuOpen && props.selectedSubmenu) && isActive

        if (!links || links.length === 0) {
          return null
        }

        return (
          <li key={linkKey + i}>
            <button
              type="button"
              className={clsx(
                styles.drawerMenuItem,
                styles.drawerMenuSubMenuItem
              )}
              data-tag_item={linkKey}
              data-tid={linkKey}
              onClick={() => props.handleOpenSubMenu(linkKey)}
            >
              {linkKey}{' '}
              <ChevronRightIcon className={styles.drawerMenuChevronRight} />
            </button>

            <FocusTrap
              className={clsx(
                styles.drawerMenuSubMenu,
                props.isSubMenuOpen && props.selectedSubmenu === linkKey
                  ? styles.drawerSubMenuVisible
                  : styles.drawerSubMenuHidden
              )}
              enabled={shouldTrapFocus}
              timeout={animationDuration}
              ref={(node: HTMLDivElement | null) => {
                if (node) {
                  setAnimationDuration(getAnimationDuration(node))
                }
              }}
            >
              <header
                className={clsx(
                  styles.drawerMenuHeader,
                  styles.drawerMenuLeftAlign
                )}
              >
                <IconButton
                  variant="anchor"
                  icon={<ArrowLeftIcon aria-hidden="true" />}
                  label={
                    <>
                      <span className="sr-only"> Back to</span> Main Menu
                    </>
                  }
                  showLabel
                  iconPosition="left"
                  onClick={props.onSubMenuClose}
                  type="button"
                />
              </header>

              <ul
                className={clsx(styles.drawerMenuBody, styles.drawerMenuItems)}
              >
                {links.map((link, j) => (
                  <li key={link.displayName + j}>
                    <a
                      className={clsx(
                        styles.drawerMenuItem,
                        link.linkDescription &&
                          styles.drawerMenuItemWithDescription
                      )}
                      href={link.url}
                      data-tag_item={link.dataTagItem || link.displayName}
                      data-tid={link.dataTagItem || link.displayName}
                      target={link.target || '_self'}
                      rel={link.ref || undefined}
                    >
                      {link.linkDescription && (
                        <span className={styles.drawerMenuItemDescription}>
                          {link.linkDescription}
                        </span>
                      )}
                      {link.displayName}
                    </a>
                  </li>
                ))}
              </ul>
            </FocusTrap>
          </li>
        )
      })}
    </>
  )
}
