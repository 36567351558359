import { jsonParse } from './json-parse'

const listExtractor = /<li><a href="([^\"]+)">(.+?)<\/a><\/li>/gm

export function linkExtractor(html?: string) {
  const parsedLinks = [...(html?.matchAll(listExtractor) ?? [])].map(
    ([, href, link]) => [href, link] as [string, string]
  )
  // if it can not parse the HTML content, try to parse it as JSON
  return parsedLinks.length > 0
    ? parsedLinks
    : (jsonParse(html ?? []) as [string, string][])
}
