export const nearMe: Record<string, string> = {
  '/near-me/apartments-for-rent/':
    '/search?isGenericRewrite=1&propertyTypes=apartments',
  '/near-me/furnished-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=furnished`,
  '/near-me/senior-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=senior-living`,
  '/near-me/loft-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=loft`,
  '/near-me/income-based-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=income-restricted`,
  '/near-me/new-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=new`,
  '/near-me/apartment-complexes-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments`,
  '/near-me/cheap-houses-for-rent/': `/search?isGenericRewrite=1&propertyTypes=houses&refinements=cheap`,
  '/near-me/cheap-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=cheap`,
  '/near-me/luxury-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=luxury`,
  '/near-me/pet-friendly-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=pet-friendly`,
  '/near-me/1-bedroom-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-beds`,
  '/near-me/2-bedroom-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-beds`,
  '/near-me/3-bedroom-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-beds`,
  '/near-me/studio-apartments-for-rent/': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=0-beds`,
  '/near-me/condos-for-rent/': `/search?isGenericRewrite=1&propertyTypes=condos`,
  '/near-me/houses-for-rent/': `/search?isGenericRewrite=1&propertyTypes=houses`,
  '/near-me/townhomes-for-rent/': `/search?isGenericRewrite=1&propertyTypes=townhouses`,
}

export const showNearMeInResultsSort = false
