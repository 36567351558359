/**
 * Safely try/catch JSON.parse with default value
 */
export function jsonParse<T>(data: unknown): T | undefined
export function jsonParse<T>(data: unknown, defaultValue: T): T
export function jsonParse<T>(data: unknown, defaultValue?: T | undefined) {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data)
    } catch (e) {
      return defaultValue
    }
  }
  return defaultValue
}
