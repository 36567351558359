import { REFINEMENT_SEPARATOR } from '@brand/search/refinements.const'
import type { RefinementMap } from '../search-page.types'
import { sortRefinementsSlugs } from './sort-refinement-slugs'

const hashSeparator = '+'

/**
 * Takes a RefinementMap and returns the parsed refinement slug (route param).
 *
 * @param refinementMap
 */
export function getSlugForRefinementMap(refinementMap: RefinementMap) {
  const hashes: string[] = []
  const sortedRefinementSlugs: string[] = sortRefinementsSlugs(
    Object.keys(refinementMap)
  )

  for (const slug of sortedRefinementSlugs) {
    const refinement = refinementMap[slug]

    if (refinement?.hash) {
      hashes.push(refinement.hash)
    }
  }

  if (hashes.length > 0) {
    sortedRefinementSlugs.push(hashes.join(hashSeparator))
  }

  return sortedRefinementSlugs.join(REFINEMENT_SEPARATOR)
}
