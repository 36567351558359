const reYYYYMMDD = /^(\d{4})-(\d{2})-(\d{2})$/
const reMMDDYYYY = /^(\d{2})\/(\d{2})\/(\d{4})$/

/** The date components use format 'yyyy-mm-dd'; however, when displayed to the
 * user, the format should be 'mm/dd/yyyy'.
 * - Given a string in format 'yyyy-mm-dd', returns 'mm/dd/yyyy'.
 * - However, if the string is not in that format, returns the string
 *   unmodified. This allows the user to type in values that can be validated
 *   and corrected. */
export function toMMDDYYYY(s = ''): string {
  const matches = reYYYYMMDD.exec(s || '')

  // If the string is not in the correct format, just pass it through unchanged
  // to give the user a chance to correct it.
  if (!matches) {
    return s
  }

  // Get the matches:
  // 0 = the entire match
  // 1 = the first group (yyyy)
  // 2 = the second group (mm)
  // 3 = the third group (dd)
  const [, yyyy, mm, dd] = matches

  return `${mm}/${dd}/${yyyy}`
}

/**
 * - Given a string in format 'mm/dd/yyyy/, returns 'yyyy-mm-dd'.
 * - Note we do not verify it is a correct date (for example, if user entered
 *   "99/99/9999" that would still be returned as "9999-99-99" even though that
 *   is an invalid date.) It is the responsibility of the parent component to
 *   verify the date selected.
 * - If the string is not in that format, returns the string unmodified. This
 *   allows the user to type in values that can be validated and corrected. */
export function toYYYYMMDD(s = ''): string {
  const matches = reMMDDYYYY.exec(s || '')

  // If user entered an invalid date (not in mM/dD/yyyy format)
  // then just return the raw value that the user entered.
  // If will need to be validated so the user can correct the value.
  if (!matches) {
    return s
  }

  const [, mm, dd, yyyy] = matches

  return `${yyyy}-${mm}-${dd}`
}

/** The Calendar component requires a Date object, so this function attempts to
 * create a date object from the date string (yyyy-mm-dd).
 * - If the string is not in the expected format, returns undefined */
export function toDate(s?: string): Date | undefined {
  if (!s) {
    return undefined
  }

  const matches = reYYYYMMDD.exec(s)

  // If the string is not in the correct format, return undefined so the
  // calendar will use today's date
  if (!matches) {
    return undefined
  }

  // Get the matches:
  // 0 = the entire match
  // 1 = the first group (yyyy)
  // 2 = the second group (mm)
  // 3 = the third group (dd)
  const [, yyyy, mm, dd] = matches

  // Note: we do not just pass the date string "yyyy-mm-dd" to the Date object,
  // because that would adjust the date based on the user's timezone and you
  // might end up with a date for the previous day.
  return new Date(parseInt(yyyy, 10), parseInt(mm, 10) - 1, parseInt(dd, 10))
}
