export type MonthName =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'

export type LongDayName =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
export type ShortDayName = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat'
export type DayName = LongDayName | ShortDayName

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as MonthName[]

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as LongDayName[]

export const dayShortNames = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as ShortDayName[]

export const getDayName = (number: number, isShort = false): DayName => {
  const names = isShort ? dayShortNames : dayNames
  return names[number] || ''
}

export const parseUnixTimeStamp = (time: string): string => {
  const matchedTime = /^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2})/.exec(time)

  if (matchedTime) {
    return matchedTime[1]
  }
  return time
}

// TODO: Add Short Months
export const getMonthName = (number: number): string => monthNames[number] || ''

/**
 * Convert a date object to YYYY-MM-DD format, being careful not
 * to adjust the date based on local timezone settings. Need to use the polyfill for IE
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart#Polyfill
 * @param {Date} date - Date to derive formatted string from
 * @returns {string}
 */
export const utcDateToDateString = (date: Date): string => {
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

/**
 * Convert MM/DD/YYYY to YYYY-MM-DD. Need to use the polyfill for IE
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart#Polyfill
 * @param {String} date - Date to derive formatted string from
 * @returns {string} Date in format YYYY-MM-DD
 */
export const localDateToDateString = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

/**
 * Add or subtract days to a date and return a date string in YYYY-MM-DD format.
 * @param {string} dateStr - Date in format YYYY-MM-DD
 * @param {number} deltaDays - Number of days to add or subtract from the date.
 * @returns {string} New date in format YYYY-MM-DD
 */
export const getDateDelta = (dateStr: string, deltaDays: number): string => {
  const date = new Date(dateStr)

  // Add or subtract the delta. The date object will take care of
  // month boundaries, leap years, etc
  // Note we use the UTC version of the date functions because we do not
  // want local timezone adjustments!
  date.setUTCDate(date.getUTCDate() + deltaDays)

  // Convert the date back to YYYY-MM-DD format.
  // Note we use our own function to format this because we do not
  // want local timezone adjustements!
  return utcDateToDateString(date)
}

/**
 * Given a start date, difference in days, and the current date, return the date
 * string for the new start date (initial start date plus the difference) with
 * the constraints that it cannot be:
 * 1) more than 30 days after the current date, nor
 * 2) before the current date.
 * @param {string} startDateStr - Start date in format YYYY-MM-DD
 * @param {number} deltaDays - Number of days to add or subtract from start date
 * @returns {string} New start date in format YYYY-MM-DD
 */
export const getNewStartDate = (
  startDateStr: string,
  deltaDays: number
): string => {
  // Date for start date plus delta days without constraints
  const deltaDate = new Date(getDateDelta(startDateStr, deltaDays))
  // Lower bound of date constraint
  const currentDate = new Date()
  // Upper bound of date constraint
  const currentPlusThirtyDate = new Date()
  let newStartDate = deltaDate

  // Set the upper bound date to the current date plus 30 days
  currentPlusThirtyDate.setUTCDate(currentPlusThirtyDate.getUTCDate() + 30)

  // If the desired date is past the upper bound, return the upper bound
  if (deltaDate > currentPlusThirtyDate) {
    newStartDate = currentPlusThirtyDate
  }
  // If the desired date is before the lower bound, return the lower bound
  if (deltaDate < currentDate) {
    newStartDate = currentDate
  }
  return utcDateToDateString(newStartDate)
}

/**
 * Takes in military hour and returns 12 - hour clock
 * @param {string} timeString - Time in HH:MM format
 * @returns {string} time formatted h:mm
 */
export const formatMilitaryTime = (timeString: string): string => {
  const [hour, minute] = timeString.split(':')
  const isValidHour = parseInt(hour, 10) || hour === '00'
  const isValidMinute = parseInt(minute, 10) || minute === '00'
  const isValidTime = isValidHour && isValidMinute

  if (!isValidTime) return 'Invalid time'

  const isoHour = Number(hour)
  const displayHour = isoHour % 12 || 12
  const period = isoHour < 12 || isoHour === 24 ? 'AM' : 'PM'
  return `${displayHour}:${minute} ${period}`
}

/**
 * @param {string} dateStr Date string in YYYY-MM-DD format.
 * @returns {number} The number of the day (0=Sunday, etc)
 */
export const getUTCDay = (dateStr?: string | number | Date): number => {
  if (dateStr) {
    return new Date(dateStr).getUTCDay()
  }

  return new Date().getUTCDay()
}

/**
 * @param {string} dateStr - Date string in YYYY-MM-DD format.
 * @returns {number} The number of the day of the month (0-31)
 */
export const getUTCDate = (dateStr: string | number | Date): number =>
  new Date(dateStr).getUTCDate()

export const getDateSuffix = (day: number): string => {
  if (day > 3 && day < 21) {
    return 'th'
  }

  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

/**
 * Takes in a date string YYYY-MM-DD and a military timestring HH:MM
 * and returns a string value of a long date ex: Thursday, August 1st, 9:00 AM
 * @param {string} dateString - YYYY-MM-DD
 * @param {string} timeString - HH:MM
 * @param {boolean} isShort - when true short names will be used for the date
 * @returns {string} Day, Month Day(suffix), Time
 */
export const formatDateAndTimeString = (
  dateString: string | number | Date,
  timeString: string,
  isShort = false
): string => {
  const dayName = getDayName(getUTCDay(dateString), isShort)
  const dateObj = new Date(dateString)
  const month = getMonthName(dateObj.getUTCMonth())
  const day = dateObj.getUTCDate()
  const multiFormatTimeString = parseUnixTimeStamp(timeString)
  const time = formatMilitaryTime(multiFormatTimeString)

  return `${dayName}, ${month} ${day}${getDateSuffix(day)}, ${time}`
}
//        <metrics statements="69" coveredstatements="68" conditionals="40" coveredconditionals="38" methods="12" coveredmethods="12"/>

/**
 * @returns {string} Date string in YYYY-MM-DD format, for today.
 */
export const getToday = (): string => localDateToDateString(new Date())

/**
 * @param {string} daydate - A date string in YYYY-MM-DD format.
 * @returns {boolean} Returns true if the date string matches today's date.
 */
export const isToday = (daydate: string): boolean => getToday() === daydate
