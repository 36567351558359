import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface Dimensions {
  width: number
  height: number
}

interface UseWindowSize {
  ref?: Element
  enabled?: boolean
}

let observer: ResizeObserver | null = null

export function useWindowSize({ enabled = true }: UseWindowSize): Dimensions {
  const [size, setSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  })

  const debouncedUpdateSize = useDebouncedCallback(() => {
    if (
      size.width !== window.innerWidth ||
      size.height !== window.innerHeight
    ) {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, 200)

  useEffect(() => {
    if (!enabled) return

    if (!observer) {
      observer = new ResizeObserver(debouncedUpdateSize)
      observer.observe(document.body)
    }

    return () => {
      if (observer) {
        observer?.disconnect()
        observer = null
      }
    }
  }, [enabled, size.width, size.height, debouncedUpdateSize])

  return size
}
