import { graphqlRequesterOptions as brandGraphqlRequesterOptions } from '@brand/config/graphql-requester-options'
import type { GraphqlRequesterOptions } from '@rentpath/graphql-requester'
import { getEnv } from './get-env'

const env = getEnv()

export const graphqlRequesterOptions: GraphqlRequesterOptions = {
  ...brandGraphqlRequesterOptions,
  clientVersion: env.NEXT_PUBLIC_VERSION,
}
