'use client'

import clsx from 'clsx'
import styles from './nav-links.module.css'
import sharedStyles from '@brand/slots/page-header/page-header.module.css'
import { PageHeaderSavedPageLink } from '../../../../features/page-header/page-header-saved-page-link'
import type { ReactNode } from 'react'
import { type PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'
import { list } from '../../config/shared-links'
import { PageHeaderSavedSearchesLink } from '../page-header/page-header-saved-searches-link'

type NavLinksProps = {
  marketTrendsUrl?: string | null
  location?: PageHeader_QueryFragment['location']
  mobileContent?: ReactNode
}

export const NavLinks = ({ mobileContent }: NavLinksProps) => {
  return (
    <>
      <li>
        <a
          href="https://solutions.rent.com"
          data-tag_item="business_solutions"
          className={clsx(sharedStyles.pageHeaderLink, styles.navAnchor)}
        >
          Marketing Solutions
        </a>
      </li>

      <li>
        <a
          href={list.URL}
          data-tag_item="list_a_property"
          className={clsx(sharedStyles.pageHeaderLink, styles.navAnchor)}
        >
          {list.TEXT}
        </a>
      </li>
      <>
        <li>
          <PageHeaderSavedSearchesLink />
        </li>
        <li>
          <PageHeaderSavedPageLink
            mobileContent={mobileContent}
            renderNewNavLinksDesign
          />
        </li>
      </>
    </>
  )
}
