import type { env } from './env'

export type ClientSafeEnvVars = Pick<
  ServerSideEnvVars,
  `NEXT_PUBLIC_${string}` & keyof ServerSideEnvVars
>
export type ServerSideEnvVars = typeof env

export function getEnv(): ServerSideEnvVars | ClientSafeEnvVars {
  if (typeof window !== 'undefined') {
    if (typeof window.__ENV__ === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn('`__ENV__` is missing/not defined in the client.')

      return {} as ClientSafeEnvVars
    }

    return window.__ENV__ as ClientSafeEnvVars
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require('./env').env as ServerSideEnvVars
  }
}

export function isServerSideEnvVars(
  envVars: ServerSideEnvVars | ClientSafeEnvVars
): envVars is ServerSideEnvVars {
  return typeof window === 'undefined'
}

export function isClientSafeEnvVars(
  envVars: ServerSideEnvVars | ClientSafeEnvVars
): envVars is ClientSafeEnvVars {
  return typeof window !== 'undefined'
}
