'use client'

import { useEffect, useState } from 'react'

const mediaQueryList = new Map<string, MediaQueryList>()

export function useMediaQuery(query: string, initial = false) {
  const [matches, setMatches] = useState(initial)

  useEffect(() => {
    let media = mediaQueryList.get(query)

    if (!media) {
      media = window.matchMedia(query)
      mediaQueryList.set(query, media)
    }

    setMatches(media.matches)

    const listener = (event: MediaQueryListEvent) => setMatches(event.matches)

    media.addEventListener('change', listener)

    return () => {
      media?.removeEventListener('change', listener)
    }
  }, [query])

  return matches
}
