import { atomWithStorage } from 'jotai/utils'
import Cookies from 'js-cookie'
import type { CookieAttributes } from 'js-cookie'
import type { LeadCookieData } from './lead-cookie-parser'
import { LeadCookieParser } from './lead-cookie-parser'

const leadCookieOptions: CookieAttributes = { path: '/' }

export const defaultLeadCookieData: LeadCookieData = {
  email: '',
  name: '',
  phone: '',
  moveDate: '',
}

const cookiesConfigBuilder = (options: CookieAttributes) => {
  return {
    getItem: (key: string) => {
      const val = Cookies.get(key)

      return val ? LeadCookieParser.parse(val) : defaultLeadCookieData
    },
    setItem: (key: string, leadCookieData: LeadCookieData) => {
      // store in an odd format to conform to older apps while we transition
      const val = LeadCookieParser.stringify(leadCookieData)
      Cookies.set(key, val, options)

      return val
    },
    removeItem: (key: string) => {
      Cookies.remove(key, options)
    },
  }
}

const leadConfig = cookiesConfigBuilder(leadCookieOptions)

export const leadCookieAtom = atomWithStorage<LeadCookieData>(
  'lead',
  defaultLeadCookieData,
  leadConfig
)
