export function isArrayOfType<T>(
  value: unknown,
  predicate: (item: unknown) => item is T
): value is T[] {
  if (!Array.isArray(value)) {
    return false
  }

  for (const item of value) {
    if (!predicate(item)) {
      return false
    }
  }

  return true
}
