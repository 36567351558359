/**
 * Creates a function that takes a route path and returns a RefinementMap reflecting the current refinements.
 * @param refinementMap
 */
export function createRefinementExtractor<T extends Record<string, unknown>>(
  refinementMap: T
) {
  const refinementSlugsByLength = Object.keys(refinementMap).sort(
    (a, b) => b.length - a.length
  )

  /**
   * Takes a route path and returns a RefinementMap reflecting the current refinements.
   * @param path
   */
  function extractRefinements(path: string): T {
    const currentRefinementMap: Record<string, unknown> = {}

    for (let i = 0; i < refinementSlugsByLength.length; i++) {
      const slug = refinementSlugsByLength[i] + ''

      const matchedIndex = path.toLowerCase().indexOf(slug.toLowerCase())

      if (matchedIndex !== -1) {
        currentRefinementMap[slug] = refinementMap[slug]
        path = path.replace(slug, '')
      }
    }

    return currentRefinementMap as T
  }

  return extractRefinements
}
